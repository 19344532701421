import BallIcon from "assets/icons/Ball.png"
import BrandIcon from "assets/icons/Brand.png"
import HeadIcon from "assets/icons/Head.png"
import ManagementIcon from "assets/icons/Management.png"
import ShaftIcon from "assets/icons/Shaft.png"
import PurchaseIcon from "assets/icons/dollar.svg"
import PutterIcon from "assets/icons/putter.png"
import {
	ROUTE_ADMIN,
	ROUTE_ADMIN_CREATE,
	ROUTE_ADMIN_EDIT,
	ROUTE_APP_USERS,
	ROUTE_APP_USERS_COPY,
	ROUTE_APP_USERS_CREATE,
	ROUTE_APP_USERS_EDIT,
	ROUTE_BALLS,
	ROUTE_BALLS_CREATE,
	ROUTE_BALLS_EDIT,
	ROUTE_BODYANALYSIS,
	ROUTE_BODYANALYSIS_DETAILS,
	ROUTE_BODY_TYPES,
	ROUTE_BODY_TYPES_DETAIL,
	ROUTE_BRANDS,
	ROUTE_BRANDS_CREATE,
	ROUTE_BRANDS_EDIT,
	ROUTE_CUSTOMERS,
	ROUTE_CUSTOMERS_COPY,
	ROUTE_CUSTOMERS_CREATE,
	ROUTE_CUSTOMERS_DETAIL,
	ROUTE_CUSTOMERS_EDIT,
	ROUTE_DRIVER_HEADS,
	ROUTE_DRIVER_HEADS_COPY,
	ROUTE_DRIVER_HEADS_CREATE,
	ROUTE_DRIVER_HEADS_EDIT,
	ROUTE_DRIVER_SHAFTS,
	ROUTE_DRIVER_SHAFTS_COPY,
	ROUTE_DRIVER_SHAFTS_CREATE,
	ROUTE_DRIVER_SHAFTS_EDIT,
	ROUTE_FITTING_MANAGEMENTS,
	ROUTE_FITTING_MANAGEMENTS_DETAIL,
	ROUTE_GLOVES,
	ROUTE_GLOVES_COPY,
	ROUTE_GLOVES_CREATE,
	ROUTE_GLOVES_EDIT,
	ROUTE_HELP_ADMIN,
	ROUTE_HELP_ADMIN_DETAIL,
	ROUTE_HELP_APP,
	ROUTE_HELP_APP_DETAIL,
	ROUTE_HYBRID_HEADS,
	ROUTE_HYBRID_HEADS_COPY,
	ROUTE_HYBRID_HEADS_CREATE,
	ROUTE_HYBRID_HEADS_EDIT,
	ROUTE_HYBRID_SHAFTS,
	ROUTE_HYBRID_SHAFTS_COPY,
	ROUTE_HYBRID_SHAFTS_CREATE,
	ROUTE_HYBRID_SHAFTS_EDIT,
	ROUTE_IRON_HEADS,
	ROUTE_IRON_HEAD_COPY,
	ROUTE_IRON_HEAD_CREATE,
	ROUTE_IRON_HEAD_EDIT,
	ROUTE_IRON_SETS,
	ROUTE_IRON_SETS_COPY,
	ROUTE_IRON_SETS_CREATE,
	ROUTE_IRON_SETS_EDIT,
	ROUTE_IRON_SHAFTS,
	ROUTE_IRON_SHAFT_COPY,
	ROUTE_IRON_SHAFT_CREATE,
	ROUTE_IRON_SHAFT_EDIT,
	ROUTE_MATCH_HISTORIES,
	ROUTE_MATCH_HISTORIES_FITTING,
	ROUTE_PLAYGROUND,
	ROUTE_PROMOTIONS,
	ROUTE_PROMOTIONS_CREATE,
	ROUTE_PROMOTIONS_EDIT,
	ROUTE_PURCHASE,
	ROUTE_PURCHASE_NUMBER,
	ROUTE_PUTTER,
	ROUTE_PUTTER_HEADS,
	ROUTE_PUTTER_HEADS_COPY,
	ROUTE_PUTTER_HEADS_CREATE,
	ROUTE_PUTTER_HEADS_EDIT,
	ROUTE_PUTTER_SHAFTS,
	ROUTE_PUTTER_SHAFTS_COPY,
	ROUTE_PUTTER_SHAFTS_CREATE,
	ROUTE_PUTTER_SHAFTS_EDIT,
	ROUTE_RECOMMENDED_SPEC_CALCULATOR,
	ROUTE_RESERVATIONS,
	ROUTE_RESERVATION_DETAILS,
	ROUTE_SETTINGS,
	ROUTE_STORES,
	ROUTE_STORES_COPY,
	ROUTE_STORES_CREATE,
	ROUTE_STORES_EDIT,
	ROUTE_WEDGE_HEADS,
	ROUTE_WEDGE_HEAD_COPY,
	ROUTE_WEDGE_HEAD_CREATE,
	ROUTE_WEDGE_HEAD_EDIT,
	ROUTE_WEDGE_SHAFTS,
	ROUTE_WEDGE_SHAFT_COPY,
	ROUTE_WEDGE_SHAFT_CREATE,
	ROUTE_WEDGE_SHAFT_EDIT,
	ROUTE_WOOD_HEADS,
	ROUTE_WOOD_HEADS_COPY,
	ROUTE_WOOD_HEADS_CREATE,
	ROUTE_WOOD_HEADS_EDIT,
	ROUTE_WOOD_SHAFTS,
	ROUTE_WOOD_SHAFTS_COPY,
	ROUTE_WOOD_SHAFTS_CREATE,
	ROUTE_WOOD_SHAFTS_EDIT,
} from "urls"

const routes = [
	{
		name: "routes.customers",
		id: "customers",
		icon: "person",
		path: ROUTE_CUSTOMERS,
		lazy: () => import("layouts/customers/list"),
		access: ["admin"],
		routes: [
			{
				path: ROUTE_CUSTOMERS_CREATE,
				lazy: () =>
					import("layouts/customers/edit").then(({ CustomerCreate }) => ({
						Component: CustomerCreate,
					})),
				access: ["admin"],
			},
			{
				path: ROUTE_CUSTOMERS_COPY,
				lazy: () =>
					import("layouts/customers/edit").then(({ CustomerEdit }) => ({
						Component: () => <CustomerEdit copy />,
					})),
				access: ["admin"],
			},
			{
				path: ROUTE_CUSTOMERS_EDIT,
				lazy: () =>
					import("layouts/customers/edit").then(({ CustomerEdit }) => ({
						Component: () => <CustomerEdit />,
					})),
				access: ["admin"],
			},
			{
				path: ROUTE_CUSTOMERS_DETAIL,
				lazy: () => import("layouts/customers/details"),
				access: ["admin"],
			},
		],
	},
	{
		name: "routes.match-histories",
		id: "match-histories",
		icon: "history_outline",
		path: ROUTE_MATCH_HISTORIES,
		lazy: () => import("layouts/matchHistories/list"),
		access: ["admin"],
		routes: [
			{
				path: ROUTE_MATCH_HISTORIES_FITTING,
				lazy: () => import("layouts/matchHistories/detail"),
				access: ["admin"],
			},
		],
	},
	{
		name: "routes.brands",
		id: "brands",
		icon: <img src={BrandIcon} alt="Icon" className="icon-image" />,
		path: ROUTE_BRANDS,
		lazy: () => import("layouts/brands/list"),
		access: ["admin"],
		routes: [
			{
				path: ROUTE_BRANDS_CREATE,
				lazy: () => import("layouts/brands/create"),
				access: ["admin"],
			},
			{
				path: ROUTE_BRANDS_EDIT,
				lazy: () => import("layouts/brands/edit"),
				access: ["admin"],
			},
		],
	},
	{
		name: "Reservation",
		id: "reservation",
		icon: "calendar_today",
		path: ROUTE_RESERVATIONS,
		lazy: () => import("layouts/reservations/list"),
		access: ["admin"],
		routes: [
			{
				path: ROUTE_RESERVATION_DETAILS,
				lazy: () => import("layouts/reservations/details"),
				access: ["admin"],
			},
		],
	},
	{
		name: "routes.driver.driver",
		icon: "sports_golf",
		id: "driver",
		access: ["admin"],
		children: [
			{
				name: "routes.heads",
				icon: <img src={HeadIcon} alt="Icon" className="icon-image" />,
				path: ROUTE_DRIVER_HEADS,
				lazy: () => import("layouts/clubs/drivers/heads/list"),
				access: ["admin"],
				routes: [
					{
						path: ROUTE_DRIVER_HEADS_CREATE,
						lazy: () => import("layouts/clubs/drivers/heads/create"),
						access: ["admin"],
					},
					{
						path: ROUTE_DRIVER_HEADS_COPY,
						lazy: () =>
							import("layouts/clubs/drivers/heads/edit").then(({ Component: DriverHeadEdit }) => ({
								Component: () => <DriverHeadEdit copy />,
							})),
						access: ["admin"],
					},
					{
						path: ROUTE_DRIVER_HEADS_EDIT,
						lazy: () => import("layouts/clubs/drivers/heads/edit"),
						access: ["admin"],
					},
				],
			},
			{
				name: "routes.shafts",
				icon: <img src={ShaftIcon} alt="Icon" className="icon-image" />,
				path: ROUTE_DRIVER_SHAFTS,
				lazy: () => import("layouts/clubs/drivers/shafts/list"),
				access: ["admin"],
				routes: [
					{
						path: ROUTE_DRIVER_SHAFTS_CREATE,
						lazy: () => import("layouts/clubs/drivers/shafts/create"),
						access: ["admin"],
					},
					{
						path: ROUTE_DRIVER_SHAFTS_COPY,
						lazy: () =>
							import("layouts/clubs/drivers/shafts/edit").then(
								({ Component: DriverShaftEdit }) => ({
									Component: () => <DriverShaftEdit copy />,
								})
							),
						access: ["admin"],
					},
					{
						path: ROUTE_DRIVER_SHAFTS_EDIT,
						lazy: () => import("layouts/clubs/drivers/shafts/edit"),
						access: ["admin"],
					},
				],
			},
		],
	},
	{
		name: "Wood",
		id: "wood",
		icon: "golf_course",
		access: ["admin"],
		children: [
			{
				name: "routes.heads",
				icon: <img src={HeadIcon} alt="Icon" className="icon-image" />,
				path: ROUTE_WOOD_HEADS,
				lazy: () => import("layouts/clubs/wood/heads/list"),
				access: ["admin"],
				routes: [
					{
						path: ROUTE_WOOD_HEADS_CREATE,
						lazy: () => import("layouts/clubs/wood/heads/create"),
						access: ["admin"],
					},
					{
						path: ROUTE_WOOD_HEADS_COPY,
						lazy: () =>
							import("layouts/clubs/wood/heads/edit").then(({ Component }) => ({
								Component: () => <Component copy />,
							})),
						access: ["admin"],
					},
					{
						path: ROUTE_WOOD_HEADS_EDIT,
						lazy: () => import("layouts/clubs/wood/heads/edit"),
						access: ["admin"],
					},
				],
			},
			{
				name: "routes.shafts",
				icon: <img src={ShaftIcon} alt="Icon" className="icon-image" />,
				path: ROUTE_WOOD_SHAFTS,
				lazy: () => import("layouts/clubs/wood/shafts/list"),
				access: ["admin"],
				routes: [
					{
						path: ROUTE_WOOD_SHAFTS_CREATE,
						lazy: () => import("layouts/clubs/wood/shafts/create"),
						access: ["admin"],
					},
					{
						path: ROUTE_WOOD_SHAFTS_EDIT,
						lazy: () => import("layouts/clubs/wood/shafts/edit"),
						access: ["admin"],
					},
					{
						path: ROUTE_WOOD_SHAFTS_COPY,
						lazy: () =>
							import("layouts/clubs/wood/shafts/edit").then(({ Component: WoodShaftsEdit }) => ({
								Component: () => <WoodShaftsEdit copy />,
							})),
						access: ["admin"],
					},
				],
			},
		],
	},
	{
		name: "Hybrid",
		id: "hybrid",
		icon: "golf_course",
		access: ["admin"],
		children: [
			{
				name: "routes.heads",
				icon: <img src={HeadIcon} alt="Icon" className="icon-image" />,
				path: ROUTE_HYBRID_HEADS,
				lazy: () => import("layouts/clubs/hybrid/heads/list"),
				access: ["admin"],
				routes: [
					{
						path: ROUTE_HYBRID_HEADS_CREATE,
						lazy: () => import("layouts/clubs/hybrid/heads/create"),
						access: ["admin"],
					},
					{
						path: ROUTE_HYBRID_HEADS_COPY,
						lazy: () =>
							import("layouts/clubs/hybrid/heads/edit").then(({ Component }) => ({
								Component: () => <Component copy />,
							})),
						access: ["admin"],
					},
					{
						path: ROUTE_HYBRID_HEADS_EDIT,
						lazy: () => import("layouts/clubs/hybrid/heads/edit"),
						access: ["admin"],
					},
				],
			},
			{
				name: "routes.shafts",
				icon: <img src={ShaftIcon} alt="Icon" className="icon-image" />,
				path: ROUTE_HYBRID_SHAFTS,
				lazy: () => import("layouts/clubs/hybrid/shafts/list"),
				access: ["admin"],
				routes: [
					{
						path: ROUTE_HYBRID_SHAFTS_CREATE,
						lazy: () => import("layouts/clubs/hybrid/shafts/create"),
						access: ["admin"],
					},
					{
						path: ROUTE_HYBRID_SHAFTS_COPY,
						lazy: () =>
							import("layouts/clubs/hybrid/shafts/edit").then(({ Component: HybridShaftEdit }) => ({
								Component: () => <HybridShaftEdit copy />,
							})),
						access: ["admin"],
					},
					{
						path: ROUTE_HYBRID_SHAFTS_EDIT,
						lazy: () => import("layouts/clubs/hybrid/shafts/edit"),
						access: ["admin"],
					},
				],
			},
		],
	},
	{
		name: "routes.iron.iron",
		icon: "golf_course",
		id: "iron",
		access: ["admin"],
		children: [
			{
				name: "routes.heads",
				icon: <img src={HeadIcon} alt="Icon" className="icon-image" />,
				path: ROUTE_IRON_HEADS,
				lazy: () => import("layouts/clubs/iron/heads/list"),
				access: ["admin"],
				routes: [
					{
						path: ROUTE_IRON_HEAD_CREATE,
						lazy: () =>
							import("layouts/clubs/iron/heads/edit").then(({ IronHeadCreate }) => ({
								Component: IronHeadCreate,
							})),
						access: ["admin"],
					},
					{
						path: ROUTE_IRON_HEAD_EDIT,
						lazy: () =>
							import("layouts/clubs/iron/heads/edit").then(({ IronHeadEdit }) => ({
								Component: IronHeadEdit,
							})),
						access: ["admin"],
					},
					{
						path: ROUTE_IRON_HEAD_COPY,
						lazy: () =>
							import("layouts/clubs/iron/heads/edit").then(({ IronHeadEdit }) => ({
								Component: () => <IronHeadEdit copy />,
							})),
						access: ["admin"],
					},
				],
			},
			{
				name: "routes.shafts",
				icon: <img src={ShaftIcon} alt="Icon" className="icon-image" />,
				path: ROUTE_IRON_SHAFTS,
				lazy: () => import("layouts/clubs/iron/shafts/list"),
				access: ["admin"],
				routes: [
					{
						path: ROUTE_IRON_SHAFT_CREATE,
						lazy: () =>
							import("layouts/clubs/iron/shafts/edit").then(({ IronShaftsCreate }) => ({
								Component: IronShaftsCreate,
							})),
						access: ["admin"],
					},
					{
						path: ROUTE_IRON_SHAFT_EDIT,
						lazy: () =>
							import("layouts/clubs/iron/shafts/edit").then(({ IronShaftsEdit }) => ({
								Component: IronShaftsEdit,
							})),
						access: ["admin"],
					},
					{
						path: ROUTE_IRON_SHAFT_COPY,
						lazy: () =>
							import("layouts/clubs/iron/shafts/edit").then(({ IronShaftsEdit }) => ({
								Component: () => <IronShaftsEdit copy />,
							})),
						access: ["admin"],
					},
				],
			},
			{
				name: "Sets",
				icon: "redeem",
				path: ROUTE_IRON_SETS,
				lazy: () => import("layouts/clubs/iron/sets/list"),
				access: ["admin"],
				routes: [
					{
						path: ROUTE_IRON_SETS_CREATE,
						lazy: () => import("layouts/clubs/iron/sets/create"),
						access: ["admin"],
					},
					{
						path: ROUTE_IRON_SETS_EDIT,
						lazy: () => import("layouts/clubs/iron/sets/edit"),
						access: ["admin"],
					},
					{
						path: ROUTE_IRON_SETS_COPY,
						lazy: () =>
							import("layouts/clubs/iron/sets/edit").then(({ Component }) => ({
								Component: () => <Component copy />,
							})),
						access: ["admin"],
					},
				],
			},
		],
	},
	{
		name: "routes.wedge.wedge",
		icon: "golf_course",
		id: "wedge",
		access: ["admin"],
		children: [
			{
				name: "routes.heads",
				icon: <img src={HeadIcon} alt="Icon" className="icon-image" />,
				path: ROUTE_WEDGE_HEADS,
				lazy: () => import("layouts/clubs/wedge/heads/list"),
				access: ["admin"],
				routes: [
					{
						path: ROUTE_WEDGE_HEAD_CREATE,
						lazy: () => import("layouts/clubs/wedge/heads/create"),
						access: ["admin"],
					},
					{
						path: ROUTE_WEDGE_HEAD_EDIT,
						lazy: () => import("layouts/clubs/wedge/heads/edit"),
						access: ["admin"],
					},
					{
						path: ROUTE_WEDGE_HEAD_COPY,
						lazy: () =>
							import("layouts/clubs/wedge/heads/edit").then(({ Component: WedgeHeadEdit }) => ({
								Component: () => <WedgeHeadEdit copy />,
							})),
						access: ["admin"],
					},
				],
			},
			{
				name: "routes.shafts",
				icon: <img src={ShaftIcon} alt="Icon" className="icon-image" />,
				path: ROUTE_WEDGE_SHAFTS,
				lazy: () => import("layouts/clubs/wedge/shafts/list"),
				access: ["admin"],
				routes: [
					{
						path: ROUTE_WEDGE_SHAFT_CREATE,
						lazy: () => import("layouts/clubs/wedge/shafts/create"),
						access: ["admin"],
					},
					{
						path: ROUTE_WEDGE_SHAFT_EDIT,
						lazy: () => import("layouts/clubs/wedge/shafts/edit"),
						access: ["admin"],
					},
					{
						path: ROUTE_WEDGE_SHAFT_COPY,
						lazy: () =>
							import("layouts/clubs/wedge/shafts/edit").then(({ Component: WedgeShaftsEdit }) => ({
								Component: () => <WedgeShaftsEdit copy />,
							})),
						access: ["admin"],
					},
				],
			},
		],
	},
	{
		name: "Putter",
		icon: <img src={PutterIcon} alt="Icon" className="icon-image" />,
		id: "putter",
		access: ["admin"],
		path: ROUTE_PUTTER,
		lazy: () => import("layouts/clubs/putter/heads/list"),
		children: [
			{
				name: "routes.heads",
				icon: <img src={HeadIcon} alt="Icon" className="icon-image" />,
				path: ROUTE_PUTTER_HEADS,
				lazy: () => import("layouts/clubs/putter/heads/list"),
				access: ["admin"],
				routes: [
					{
						path: ROUTE_PUTTER_HEADS_CREATE,
						lazy: () => import("layouts/clubs/putter/heads/create"),
						access: ["admin"],
					},
					{
						path: ROUTE_PUTTER_HEADS_COPY,
						lazy: () =>
							import("layouts/clubs/putter/heads/edit").then(({ Component: DriverHeadEdit }) => ({
								Component: () => <DriverHeadEdit copy />,
							})),
						access: ["admin"],
					},
					{
						path: ROUTE_PUTTER_HEADS_EDIT,
						lazy: () => import("layouts/clubs/putter/heads/edit"),
						access: ["admin"],
					},
				],
			},
			{
				name: "routes.shafts",
				icon: <img src={ShaftIcon} alt="Icon" className="icon-image" />,
				path: ROUTE_PUTTER_SHAFTS,
				lazy: () => import("layouts/clubs/putter/shafts/list"),
				access: ["admin"],
				routes: [
					{
						path: ROUTE_PUTTER_SHAFTS_CREATE,
						lazy: () => import("layouts/clubs/putter/shafts/create"),
						access: ["admin"],
					},
					{
						path: ROUTE_PUTTER_SHAFTS_EDIT,
						lazy: () => import("layouts/clubs/putter/shafts/edit"),
						access: ["admin"],
					},
					{
						path: ROUTE_PUTTER_SHAFTS_COPY,
						lazy: () =>
							import("layouts/clubs/putter/shafts/edit").then(({ Component: PutterShaftEdit }) => ({
								Component: () => <PutterShaftEdit copy />,
							})),
						access: ["admin"],
					},
				],
			},
		],
	},
	{
		name: "routes.balls",
		id: "balls",
		icon: <img src={BallIcon} alt="Icon" className="icon-image" />,
		path: ROUTE_BALLS,
		lazy: () => import("layouts/balls/list"),
		access: ["admin"],
		routes: [
			{
				path: ROUTE_BALLS_CREATE,
				lazy: () => import("layouts/balls/create"),
				access: ["admin"],
			},
			{
				path: ROUTE_BALLS_EDIT,
				lazy: () => import("layouts/balls/edit"),
				access: ["admin"],
			},
		],
	},
	{
		name: "routes.stores",
		id: "stores",
		icon: "store_front",
		path: ROUTE_STORES,
		lazy: () => import("layouts/stores"),
		access: ["admin"],
		routes: [
			{
				path: ROUTE_STORES_CREATE,
				lazy: () => import("layouts/stores/create"),
				access: ["admin"],
			},
			{
				path: ROUTE_STORES_COPY,
				lazy: () => import("layouts/stores/copy"),
				access: ["admin"],
			},
			{
				path: ROUTE_STORES_EDIT,
				lazy: () => import("layouts/stores/edit"),
				access: ["admin"],
			},
		],
	},
	{
		name: "routes.purchase-history",
		id: "purchase-history",
		icon: <img src={PurchaseIcon} alt="Icon" className="icon-image" />,
		path: ROUTE_PURCHASE,
		lazy: () => import("layouts/purchaseHistory/list"),
		access: ["admin", "builder"],
		routes: [
			{
				path: ROUTE_PURCHASE_NUMBER,
				lazy: () => import("layouts/purchaseHistory/details"),
				access: ["admin", "builder"],
			},
		],
	},
	// {
	// 	name: "routes.payments",
	// 	id: "payments",
	// 	icon: "receipt",
	// 	path: ROUTE_PAYMENTS,
	// 	lazy: () => import("layouts/payments/list"),
	// 	access: ["admin"],
	// 	routes: [
	// 		{
	// 			route: ROUTE_PAYMENTS_DETAILS,
	// 			lazy: () => import("layouts/payments/detail"),
	// 			access: ["admin"],
	// 		},
	// 	],
	// },
	{
		name: "routes.bodyTypes",
		id: "body-types",
		icon: "settings_accessibility",
		path: ROUTE_BODY_TYPES,
		lazy: () => import("layouts/bodyTypes/list"),
		access: ["admin"],
		routes: [
			{
				path: ROUTE_BODY_TYPES_DETAIL,
				lazy: () => import("layouts/bodyTypes/details"),
				access: ["admin"],
			},
		],
	},
	{
		name: "routes.fittingManagement",
		id: "fitting-management",
		icon: <img src={ManagementIcon} alt="Icon" className="icon-image" />,
		path: ROUTE_FITTING_MANAGEMENTS,
		lazy: () => import("layouts/fittingManagement/list"),
		access: ["admin"],
		routes: [
			{
				path: ROUTE_FITTING_MANAGEMENTS_DETAIL,
				lazy: () => import("layouts/fittingManagement/details"),
				access: ["admin"],
			},
		],
	},
	{
		name: "routes.bodyanalysis",
		id: "body-analysis",
		icon: "accessibility",
		path: ROUTE_BODYANALYSIS,
		lazy: () => import("layouts/bodyAnalysis/list"),
		access: ["admin"],
		routes: [
			{
				path: ROUTE_BODYANALYSIS_DETAILS,
				lazy: () => import("layouts/bodyAnalysis/detail"),
				access: ["admin"],
			},
		],
	},
	{
		name: "routes.users.users",
		id: "users",
		icon: "manage_accounts",
		access: ["admin"],
		children: [
			{
				name: "routes.users.admin",
				path: ROUTE_ADMIN,
				icon: "admin_panel_settings",
				lazy: () => import("layouts/users/admin/list"),
				access: ["admin"],
				routes: [
					{
						path: ROUTE_ADMIN_CREATE,
						lazy: () => import("layouts/users/admin/create"),
						access: ["admin"],
					},
					{
						path: ROUTE_ADMIN_EDIT,
						lazy: () => import("layouts/users/admin/edit"),
						access: ["admin"],
					},
				],
			},
			{
				name: "routes.users.app",
				path: ROUTE_APP_USERS,
				icon: "smartphone",
				lazy: () => import("layouts/users/app/list"),
				access: ["admin"],
				routes: [
					{
						path: ROUTE_APP_USERS_CREATE,
						lazy: () =>
							import("layouts/users/app/create").then(({ AppUserCreate }) => ({
								Component: () => <AppUserCreate />,
							})),
						access: ["admin"],
					},
					{
						path: ROUTE_APP_USERS_COPY,
						lazy: () =>
							import("layouts/users/app/create").then(({ AppUserCopy }) => ({
								Component: () => <AppUserCopy />,
							})),
						access: ["admin"],
					},
					{
						path: ROUTE_APP_USERS_EDIT,
						lazy: () => import("layouts/users/app/edit"),
						access: ["admin"],
					},
				],
			},
		],
	},
	{
		name: "Gloves",
		id: "gloves",
		icon: "back_hand",
		path: ROUTE_GLOVES,
		lazy: () => import("layouts/gloves"),
		access: ["admin"],
		routes: [
			{
				path: ROUTE_GLOVES_CREATE,
				lazy: () => import("layouts/gloves/create"),
				access: ["admin"],
			},
			{
				path: ROUTE_GLOVES_COPY,
				lazy: () =>
					import("layouts/gloves/edit").then(({ Component }) => ({
						Component: () => <Component copy />,
					})),
				access: ["admin"],
			},
			{
				path: ROUTE_GLOVES_EDIT,
				lazy: () => import("layouts/gloves/edit"),
				access: ["admin"],
			},
		],
	},
	{
		name: "routes.settings",
		id: "settings",
		icon: "settings",
		path: ROUTE_SETTINGS,
		lazy: () => import("layouts/settings"),
		access: ["admin"],
	},
	{
		name: "routes.recommendedSpecCalculator",
		id: "recommendedSpecCalculator",
		icon: "recommendedSpecCalculator",
		path: ROUTE_RECOMMENDED_SPEC_CALCULATOR,
		lazy: () => import("layouts/recommendedSpecCalculator"),
		access: ["admin"],
	},
	{
		name: "Promotions",
		id: "promotions",
		icon: "emoji_events",
		path: ROUTE_PROMOTIONS,
		lazy: () => import("layouts/promotions/list"),
		access: ["admin"],
		routes: [
			{
				access: ["admin"],
				path: ROUTE_PROMOTIONS_CREATE,
				lazy: () => import("layouts/promotions/create"),
			},
			{
				access: ["admin"],
				path: ROUTE_PROMOTIONS_EDIT,
				lazy: () => import("layouts/promotions/edit"),
			},
		],
	},
	{
		name: "routes.help",
		id: "help",
		icon: "help",
		access: ["admin"],
		children: [
			{
				name: "Admin",
				access: ["admin"],
				path: ROUTE_HELP_ADMIN,
				icon: "contact_page",
				lazy: () => import("layouts/help/admin/list"),
				routes: [
					{
						path: ROUTE_HELP_ADMIN_DETAIL,
						lazy: () => import("layouts/help/admin/detail"),
						access: ["admin"],
					},
				],
			},
			{
				name: "App",
				access: ["admin"],
				path: ROUTE_HELP_APP,
				icon: "on_device_training",
				lazy: () => import("layouts/help/app/list"),
				routes: [
					{
						path: ROUTE_HELP_APP_DETAIL,
						lazy: () => import("layouts/help/app/detail"),
						access: ["admin"],
					},
				],
			},
		],
	},
]

if (process.env.NODE_ENV === "development") {
	routes.push({
		name: "Playground",
		id: "playground",
		icon: "abc",
		path: ROUTE_PLAYGROUND,
		lazy: () => import("layouts/playground"),
		access: ["admin"],
	})
}

export default routes
