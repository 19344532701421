export const API_LOGOUT = "/logout"
export const API_LOGIN = "/login"
export const API_ACCOUNT = "/account"
export const API_USERS = "/users"
export const API_FITTING_HISTORIES = "/fitting"
export const API_BALL_INFO = "/ball-info"
export const API_BALL_INFO_SYNC_STORES = `${API_BALL_INFO}/sync-stores`
export const API_SITE_SETTINGS = "/site-setting"
export const API_MOBILE_SETTINGS = `${API_SITE_SETTINGS}/vicx-mobile`

export const API_APP_USERS = "/app-users"
export const API_APP_USERS_ALL = `${API_APP_USERS}/all`

export const API_BRANDS = "/brands"
export const API_BRANDS_ALL = `${API_BRANDS}/all`
export const API_BRANDS_LIST_FILTER = `${API_BRANDS}/list`

export const API_CLUB_HEADS = "/club-heads"
export const API_CLUB_HEADS_ALL = `${API_CLUB_HEADS}/all`

export const API_SHAFTS = "/shafts"
export const API_SHAFTS_ALL = `${API_SHAFTS}/all`
export const API_SHAFTS_LIST = `${API_SHAFTS}/list`

export const API_DRIVER_SHAFTS = "/shafts"
export const API_DRIVER_SHAFT_FLEXES = `${API_DRIVER_SHAFTS}/flexes`
export const API_CLUB_STOCK_SHAFTS = (clubHeadId) => `${API_CLUB_HEADS}/${clubHeadId}/stock-shafts`

export const API_INSTOCK_HEADS = "/instock-heads/bulk-action"
export const API_INSTOCK_SHAFTS = "/instock-shafts/bulk-action"

export const API_STORES = "/stores"
export const API_STORES_LIST = `${API_STORES}/all`
export const API_STORE_COPY = `${API_STORES}/copy`

export const API_STORES_HELP = `${API_STORES}/help`
export const API_STORES_HELP_ANSWER = (id) => `${API_STORES_HELP}/${id}/answer`
export const API_HELP_APP = "/users/help"

export const API_DEVICES = "/devices"

export const API_ANALYZERS = `/analyzers`
export const API_ANALYZERS_ALL = `${API_ANALYZERS}/all`

export const API_ORDERS = "/order"
export const API_ORDERS_RENTAL_OVERVIEW = `${API_ORDERS}/rental/overview`

export const API_COMMENTS = "/comment"
export const API_PURCHASES = "/purchase"
export const API_COMMENTS_PURCHASE = `${API_COMMENTS}${API_PURCHASES}`
export const API_PURCHASE_STATUSES = `${API_PURCHASES}/status-list`

export const API_IRON_HEADS = "/iron-heads"
export const API_IRON_GET_HEAD = API_IRON_HEADS
export const API_IRON_GET_HEADS_ALL = `${API_IRON_HEADS}/all`
export const API_IRON_HEADS_LIST = `${API_IRON_HEADS}/list`
export const API_IRON_HEADS_SYNC_STORES = `${API_IRON_HEADS}/sync-stores`

export const API_IRON_SHAFTS = "/iron-shafts"
export const API_IRON_SHAFTS_ALL = `${API_IRON_SHAFTS}/all`
export const API_IRON_SHAFTS_LIST = `${API_IRON_SHAFTS}/list`
export const API_IRON_SHAFTS_SYNC_STORES = `${API_IRON_SHAFTS}/sync-stores`

export const API_IRON_SETS = "/iron-sets"
export const API_IRON_STOCK_SHAFTS_BY_SET = (setId) => `${API_IRON_SETS}/${setId}/stock-shafts`

export const API_PUTTER_HEADS = "/putter-heads"
export const API_PUTTER_HEADS_LIST = `${API_PUTTER_HEADS}/list`
export const API_INSTOCK_PUTTER_HEADS = `${API_PUTTER_HEADS}/sync-stores`

export const API_PUTTER_SHAFTS = "/putter-shafts"
export const API_PUTTER_SHAFTS_LIST = `${API_PUTTER_SHAFTS}/list`
export const API_INSTOCK_PUTTER_SHAFTS = `${API_PUTTER_SHAFTS}/sync-stores`
export const API_PUTTER_STOCK_SHAFTS = (clubHeadId) =>
	`${API_PUTTER_HEADS}/${clubHeadId}/stock-shafts`

export const API_WEDGE_HEADS = "wedge-heads"
export const API_WEDGE_HEADS_LIST = `${API_WEDGE_HEADS}/list`
export const API_INSTOCK_WEDGE_HEADS = `${API_WEDGE_HEADS}/sync-stores`

export const API_WEDGE_SHAFTS = "/wedge-shafts"
export const API_WEDGE_SHAFTS_LIST = `${API_WEDGE_SHAFTS}/list`
export const API_INSTOCK_WEDGE_SHAFTS = `${API_WEDGE_SHAFTS}/sync-stores`
export const API_WEDGE_STOCK_SHAFTS = (clubHeadId) =>
	`${API_WEDGE_HEADS}/${clubHeadId}/stock-shafts`

export const API_BODYANALYSIS = "/body-analysis"

export const API_PAYMENT = "/payments"

export const API_STORE_MEMBERS = "/store-members"

export const API_GLOVES = "/gloves"
export const API_GLOVES_INSTOCK = "/instock-gloves/bulk-action"

export const API_HYBRID_HEADS = "/hybrid-heads"
export const API_INSTOCK_HYBRID_HEADS = `${API_HYBRID_HEADS}/sync-stores`
export const API_HYBRID_HEADS_LIST = `${API_HYBRID_HEADS}/list`

export const API_HYBRID_SHAFTS = "/hybrid-shafts"
export const API_HYBRID_SHAFTS_LIST = `${API_HYBRID_SHAFTS}/list`
export const API_INSTOCK_HYBRID_SHAFTS = `${API_HYBRID_SHAFTS}/sync-stores`
export const API_HYBRID_STOCK_SHAFTS = (clubHeadId) =>
	`${API_HYBRID_HEADS}/${clubHeadId}/stock-shafts`

export const API_WOOD_HEADS = "/wood-heads"
export const API_INSTOCK_WOOD_HEADS = `${API_WOOD_HEADS}/sync-stores`
export const API_WOOD_HEADS_LIST = `${API_WOOD_HEADS}/list`

export const API_WOOD_SHAFTS = "/wood-shafts"
export const API_WOOD_SHAFTS_LIST = `${API_WOOD_SHAFTS}/list`
export const API_INSTOCK_WOOD_SHAFTS = `${API_WOOD_SHAFTS}/sync-stores`
export const API_WOOD_STOCK_SHAFTS = (clubHeadId) => `${API_WOOD_HEADS}/${clubHeadId}/stock-shafts`

export const API_USER_ADDRESSES = `${API_USERS}/addresses`

// fetch validation rules from backend
export const API_VALIDATION = "/valid"
export const API_VALIDATION_COMMON = API_VALIDATION + "/common"
export const API_VALIDATION_WEDGES = API_VALIDATION + "/wedges"
export const API_VALIDATION_DRIVERS = API_VALIDATION + "/drivers"
export const API_VALIDATION_WOODS = API_VALIDATION + "/woods"
export const API_VALIDATION_HYBRIDS = API_VALIDATION + "/hybrids"
export const API_VALIDATION_PUTTERS = API_VALIDATION + "/putters"
export const API_VALIDATION_IRONS = API_VALIDATION + "/irons"

export const API_RESERVATIONS = "/reservations"

export const API_BODY_TYPES = "/body-types"

export const API_PROMOTIONS = "/promotion-banners"

export const API_FITTING_MANAGEMENT = "/fitting-management"

export const API_RECOMMENDED_SPEC_CALCULATOR = "/recommended-spec-calc"
