import { api } from "services/api"
import { API_RECOMMENDED_SPEC_CALCULATOR } from "services/endpoints"

export const recommendedSpecCalculatorApi = api("recommendedSpecCalculatorApi").injectEndpoints({
	endpoints(b) {
		return {
			create: b.mutation({
				query(data) {
					return {
						data,
						method: "POST",
						url: API_RECOMMENDED_SPEC_CALCULATOR,
					}
				},
			}),
		}
	},
})
