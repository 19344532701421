import { api } from "services/api"
import { API_IRON_SETS } from "services/endpoints"
import {
	TAG_IRON_SET,
	TAG_IRON_SETS,
	TAG_IRON_SETS_CLUB_HEADS,
	TAG_IRON_SETS_WEDGE_HEADS,
} from "services/tags"

export const ironSetsApi = api("setsApi").injectEndpoints({
	endpoints(b) {
		return {
			list: b.query({
				query(params) {
					return {
						params,
						url: API_IRON_SETS,
					}
				},

				providesTags: [TAG_IRON_SETS],
			}),

			clubHeads: b.query({
				query(params) {
					return {
						params,
						url: `${API_IRON_SETS}/iron-heads`,
					}
				},

				providesTags: [TAG_IRON_SETS_CLUB_HEADS],

				transformResponse: ({ data }) =>
					data.map(({ id, name, type }) => ({ name: `${name} / ${type}`, value: id })),
			}),

			wedgeHeads: b.query({
				query(params) {
					return {
						params,
						url: `${API_IRON_SETS}/wedge-heads`,
					}
				},

				providesTags: [TAG_IRON_SETS_WEDGE_HEADS],

				transformResponse: ({ data }) =>
					data.map(({ id, name, type }) => ({ name: `${name} / ${type}`, value: id })),
			}),

			create: b.mutation({
				query(data) {
					return {
						data,
						method: "POST",
						url: API_IRON_SETS,
					}
				},

				invalidatesTags: (ok) =>
					ok ? [TAG_IRON_SETS_CLUB_HEADS, TAG_IRON_SETS_WEDGE_HEADS, TAG_IRON_SETS] : [],
			}),

			get: b.query({
				query(id) {
					return {
						url: `${API_IRON_SETS}/${id}`,
					}
				},

				transformResponse({ data }) {
					return { ...data, wedge_head_ids: data.wedgeHeads?.map(({ id }) => id) }
				},

				providesTags: [TAG_IRON_SET],
			}),

			update: b.mutation({
				query({ id, ...data }) {
					return {
						data,
						method: "PUT",
						url: `${API_IRON_SETS}/${id}`,
					}
				},

				invalidatesTags: (ok) =>
					ok
						? [TAG_IRON_SETS, TAG_IRON_SET, TAG_IRON_SETS_CLUB_HEADS, TAG_IRON_SETS_WEDGE_HEADS]
						: [],
			}),

			delete: b.mutation({
				query(id) {
					return {
						method: "DELETE",
						url: `${API_IRON_SETS}/${id}`,
					}
				},

				invalidatesTags: (ok) =>
					ok
						? [TAG_IRON_SETS, TAG_IRON_SET, TAG_IRON_SETS_CLUB_HEADS, TAG_IRON_SETS_WEDGE_HEADS]
						: [],
			}),

			toggleActive: b.mutation({
				query({ id, ...data }) {
					return {
						data,
						method: "PUT",
						url: `${API_IRON_SETS}/${id}/status`,
					}
				},

				invalidatesTags: (ok) => (ok ? [TAG_IRON_SETS, TAG_IRON_SET] : []),
			}),
		}
	},
})
