import { formSchema } from "layouts/promotions/common"
import { pick } from "lodash"
import { api } from "services/api"
import { API_PROMOTIONS } from "services/endpoints"
import { TAG_PROMOTION, TAG_PROMOTIONS } from "services/tags"

export const promotionsApi = api("promotionsApi").injectEndpoints({
	endpoints(b) {
		return {
			list: b.query({
				query(params) {
					return {
						params,
						url: API_PROMOTIONS,
					}
				},

				transformResponse: ({ data, ...rest }) => {
					return {
						...rest,
						data: data.map((item) => ({
							...item,
							name: item.id,
						})),
					}
				},

				providesTags: [TAG_PROMOTIONS],
			}),

			create: b.mutation({
				query(data) {
					return {
						data,
						method: "POST",
						url: API_PROMOTIONS,
					}
				},

				invalidatesTags: (ok) => (ok ? [TAG_PROMOTIONS] : []),
			}),

			get: b.query({
				query(id) {
					return {
						url: `${API_PROMOTIONS}/${id}`,
					}
				},

				transformResponse: ({ data: { id, photos, ...data } }) => {
					return {
						...pick(data, Object.keys(formSchema.fields)),
						id,
						photos: photos
							.sort((a, b) => a.id - b.id) // preserve upload order
							.map((photo) => ({
								id: photo.id,
								full: photo.path,
								thumbnail: photo.thumbnails[0]?.path,
							})),
					}
				},

				providesTags: [TAG_PROMOTION],
			}),

			update: b.mutation({
				query({ id, ...data }) {
					return {
						data,
						method: "PUT",
						url: `${API_PROMOTIONS}/${id}`,
					}
				},

				invalidatesTags: (ok) => (ok ? [TAG_PROMOTION, TAG_PROMOTIONS] : []),
			}),

			delete: b.mutation({
				query(id) {
					return {
						method: "DELETE",
						url: `${API_PROMOTIONS}/${id}`,
					}
				},

				invalidatesTags: (ok) => (ok ? [TAG_PROMOTION, TAG_PROMOTIONS] : []),
			}),

			deletePhoto: b.mutation({
				query(id) {
					return {
						method: "DELETE",
						url: `${API_PROMOTIONS}/photo/${id}`,
					}
				},

				// invalidatesTags: (ok) => (ok ? [TAG_PROMOTION] : []),
			}),
		}
	},
})
