import { api } from "services/api"
import { API_USERS } from "services/endpoints"
import { TAG_CUSTOMER_SHIPPING_ADDRESS, TAG_CUSTOMER_SHIPPING_ADDRESS_LIST } from "services/tags"

export default api("customerShippingAddressApi").injectEndpoints({
	endpoints(b) {
		return {
			list: b.query({
				query(params) {
					return {
						params,
						url: `${API_USERS}/${params.user_id}/addresses`,
					}
				},

				providesTags: [TAG_CUSTOMER_SHIPPING_ADDRESS_LIST],
			}),

			get: b.query({
				query(params) {
					return {
						params,
						url: `${API_USERS}/${params.user_id}/addresses/${params.id}`,
					}
				},

				providesTags: [TAG_CUSTOMER_SHIPPING_ADDRESS],
			}),

			remove: b.mutation({
				query(id) {
					return {
						method: "DELETE",
						url: `${API_USERS}/${id}/addresses/${id}`,
					}
				},

				invalidatesTags: (ok) =>
					ok ? [TAG_CUSTOMER_SHIPPING_ADDRESS_LIST, TAG_CUSTOMER_SHIPPING_ADDRESS] : [],
			}),

			create: b.mutation({
				query(data) {
					return {
						data,
						method: "POST",
						url: `${API_USERS}/${data.user_id}/addresses`,
					}
				},

				invalidatesTags: (ok) => (ok ? [TAG_CUSTOMER_SHIPPING_ADDRESS_LIST] : []),
			}),

			update: b.mutation({
				query(data) {
					return {
						data,
						method: "PUT",
						url: `${API_USERS}/${data.user_id}/addresses/${data.id}`,
					}
				},

				invalidatesTags: (ok) =>
					ok ? [TAG_CUSTOMER_SHIPPING_ADDRESS_LIST, TAG_CUSTOMER_SHIPPING_ADDRESS] : [],
			}),
		}
	},
})
