import { yupResolver } from "@hookform/resolvers/yup"
import Card from "@mui/material/Card"
import bgImage from "assets/images/bg-sign-in-basic.jpeg"
import MDBox from "components/MDBox"
import { ImplicitLoadingButton } from "components/MDButton"
import MDInput from "components/MDInput"
import MDTypography from "components/MDTypography"
import { BasicLayout } from "layouts/authentication/layout"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { authApi } from "services/modules"
import { openSnackbar } from "stores/snackbar"
import { storeUser, useUser } from "stores/user"
import { ROUTE_DEFAULT } from "urls"
import { getErrorMessage } from "utils/helpers"
import { object, string } from "yup"

const schema = object({
	email: string().email().required("common.fieldRequired"),
	password: string().required("common.fieldRequired"),
}).required()

const Login = () => {
	const { register, formState, handleSubmit, setError } = useForm({
		resolver: yupResolver(schema),
	})

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { t } = useTranslation()

	const [trigger] = authApi.useLoginMutation()

	const errors = formState.errors

	return (
		<BasicLayout image={bgImage}>
			<Card>
				<MDBox
					variant="gradient"
					bgColor="info"
					borderRadius="lg"
					coloredShadow="info"
					mx={2}
					mt={-3}
					p={2}
					mb={1}
					textAlign="center"
				>
					<MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
						{t("login.title")}
					</MDTypography>
				</MDBox>
				<MDBox pt={4} pb={3} px={3}>
					<MDBox>
						<MDBox mb={2}>
							<MDInput
								{...register("email")}
								type="email"
								label={t("login.email")}
								fullWidth
								error={Boolean(errors.email)}
							/>

							{errors.email && (
								<MDTypography fontSize={12} sx={{ color: "red" }}>
									{t(errors.email.message)}
								</MDTypography>
							)}
						</MDBox>

						<MDBox mb={2}>
							<MDInput
								{...register("password")}
								type="password"
								label={t("login.password")}
								fullWidth
								error={Boolean(errors.password)}
							/>

							{errors.password && (
								<MDTypography fontSize={12} sx={{ color: "red" }}>
									{t(errors.password.message)}
								</MDTypography>
							)}
						</MDBox>
						<MDBox mt={4} mb={1}>
							<ImplicitLoadingButton
								onClick={handleSubmit(async (values) => {
									try {
										const { data } = await trigger(values).unwrap()

										dispatch(storeUser(data.id, data.name, data.email, data.role))

										navigate(ROUTE_DEFAULT, { replace: true })
									} catch (err) {
										dispatch(openSnackbar("error", err.message))

										const errors = getErrorMessage(err)

										if (typeof errors !== "string") {
											Object.entries(errors).forEach(([key, error]) => {
												setError(key, error)
											})
										}
									}
								})}
								variant="gradient"
								color="info"
								fullWidth
							>
								{t("login.login")}
							</ImplicitLoadingButton>
						</MDBox>
					</MDBox>
				</MDBox>
			</Card>
		</BasicLayout>
	)
}

export default function WrappedLogin() {
	const navigate = useNavigate()

	const user = useUser()

	if (user.id !== null) {
		navigate(ROUTE_DEFAULT)
		return null
	}

	return <Login />
}
