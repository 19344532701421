import { DEFAULT_LANG } from "i18n"

const CHANGE_LANGUAGE = "changeLanguage"

const initialState = {
	language: DEFAULT_LANG,
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case CHANGE_LANGUAGE:
			return { language: action.language }
		default:
			return state
	}
}

export const changeLanguage = (language) => (dispatch) => {
	dispatch({ type: CHANGE_LANGUAGE, language })
}

export default reducer
