import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import PropTypes from "prop-types"
import { useRef } from "react"

import styles from "./styles"

function get(file) {
	return new Promise((resolve, reject) => {
		const fr = new FileReader()

		fr.onload = () => {
			resolve(fr.result)
		}

		fr.onerror = reject

		fr.readAsDataURL(file)
	})
}

export const handleFileInput = async (files) => {
	const newPhotos = []

	for (const file of files) {
		const photo = await get(file)

		newPhotos.push(photo)
	}

	return newPhotos
}

function ImageUpload({ imageSrc, handleSelectImage, containerStyle, svgStyle, error, ...props }) {
	const handleOnChange = async (files) => {
		const [file] = await handleFileInput(files)

		handleSelectImage(file)
	}

	const sx = containerStyle || styles.container

	sx.borderColor = error ? "red" : "rgba(0, 0, 0, 0.2)"

	sx.backgroundImage = imageSrc ? `url(${imageSrc})` : ""

	return (
		<Grid item xs={12}>
			<Box sx={sx}>
				{!imageSrc && (
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 24 24"
						style={svgStyle || styles.svg}
					>
						<path
							strokeLinecap="square"
							strokeLinejoin="square"
							strokeWidth={2}
							d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
						/>
					</svg>
				)}

				<Input handleOnChange={handleOnChange} {...props} />
			</Box>
		</Grid>
	)
}

export function Input({ handleOnChange, ...props }) {
	const fileInputRef = useRef()

	return (
		<input
			type="file"
			accept=".jpg,.jpeg,.png"
			onChange={(e) => handleOnChange(e.target.files)}
			style={styles.input}
			ref={fileInputRef}
			{...props}
		/>
	)
}

ImageUpload.defaultProps = {
	imageSrc: "",
	handleSelectImage: () => {},
	containerStyle: null,
	svgStyle: null,
}

ImageUpload.propTypes = {
	imageSrc: PropTypes.string,
	handleSelectImage: PropTypes.func,
	containerStyle: PropTypes.object,
	svgStyle: PropTypes.object,
}

export default ImageUpload
