import App from "App"
import Wrapper from "appWrapper"
import { render } from "react-dom"

import "./global.css"
import "./i18n"

render(
	<Wrapper>
		<App />
	</Wrapper>,
	document.getElementById("root")
)
