import { OMIT_ON_CLONE } from "constant"
import { omit } from "lodash"
import { commonApi } from "services/api"
import {
	API_HYBRID_SHAFTS,
	API_HYBRID_SHAFTS_LIST,
	API_INSTOCK_HYBRID_SHAFTS,
} from "services/endpoints"
import { TAG_HYBRID_SHAFT, TAG_HYBRID_SHAFTS } from "services/tags"

function updateInstockShafts(baseQuery, data) {
	return baseQuery({
		data,
		method: "POST",
		url: API_INSTOCK_HYBRID_SHAFTS,
	})
}

export default commonApi.injectEndpoints({
	endpoints(build) {
		return {
			getHybridShaftsList: build.query({
				query(params) {
					return {
						params,
						url: API_HYBRID_SHAFTS_LIST,
					}
				},

				transformResponse({ data, ...response }) {
					return {
						...response,
						data: data.map((item) => ({
							...item,
							name: item.shaft_name,
						})),
					}
				},

				providesTags: [TAG_HYBRID_SHAFTS],
			}),

			getAllHybridShafts: build.query({
				query(params) {
					return {
						params,
						url: API_HYBRID_SHAFTS,
					}
				},

				providesTags: [TAG_HYBRID_SHAFTS],
			}),

			toggleField: build.mutation({
				query({ id, field, ...data }) {
					return {
						data,
						method: "PUT",
						url: `${API_HYBRID_SHAFTS}/${id}/${field}`,
					}
				},

				invalidatesTags: (ok) => (ok ? [TAG_HYBRID_SHAFT, TAG_HYBRID_SHAFTS] : []),
			}),

			getDetailHybridShafts: build.query({
				query(id) {
					return {
						url: `${API_HYBRID_SHAFTS}/${id}`,
					}
				},

				transformResponse: ({ data: shaft }) => {
					return {
						...shaft,
						photo: shaft.photos?.path,
					}
				},

				providesTags: [TAG_HYBRID_SHAFT],
			}),

			createHybridShaft: build.mutation({
				async queryFn({ store_ids, ...data }, queryApi, extraOptions, baseQuery) {
					const { data: response, error } = await baseQuery({
						data: omit(data, OMIT_ON_CLONE),
						method: "POST",
						url: API_HYBRID_SHAFTS,
					})

					if (error) {
						return { error }
					}

					return await updateInstockShafts(baseQuery, {
						shaft_id: response.data.id,
						store_id_list: store_ids,
					})
				},

				invalidatesTags: (ok) => (ok ? [TAG_HYBRID_SHAFT, TAG_HYBRID_SHAFTS] : []),
			}),

			updateHybridShaft: build.mutation({
				async queryFn({ store_ids, id, ...data }, queryApi, extraOptions, baseQuery) {
					const { error } = await baseQuery({
						data,
						method: "PUT",
						url: `${API_HYBRID_SHAFTS}/${id}`,
					})

					if (error) {
						return { error }
					}

					return await updateInstockShafts(baseQuery, {
						shaft_id: id,
						store_id_list: store_ids,
					})
				},

				transformResponse: ({ data }) => data,

				invalidatesTags: (ok) => (ok ? [TAG_HYBRID_SHAFT, TAG_HYBRID_SHAFTS] : []),
			}),

			deleteHybridShaft: build.mutation({
				async queryFn({ id, ...data }, queryApi, extraOptions, baseQuery) {
					const { error } = await updateInstockShafts(baseQuery, data)

					if (error) {
						return { error }
					}

					return await baseQuery({
						method: "DELETE",
						url: `${API_HYBRID_SHAFTS}/${id}`,
					})
				},

				invalidatesTags: (ok) => (ok ? [TAG_HYBRID_SHAFTS] : []),
			}),
		}
	},
})
