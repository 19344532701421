import { api } from "services/api"
import { API_STORE_MEMBERS } from "services/endpoints"
import { TAG_APP_USER, TAG_APP_USERS, TAG_STORE } from "services/tags"

export default api("storeMembersApi").injectEndpoints({
	endpoints(b) {
		return {
			create: b.mutation({
				query(data) {
					return {
						data,
						method: "POST",
						url: API_STORE_MEMBERS,
					}
				},

				invalidatesTags: (ok) => (ok ? [TAG_APP_USERS, TAG_APP_USER, TAG_STORE] : []),
			}),

			delete: b.mutation({
				query(id) {
					return {
						method: "DELETE",
						url: `${API_STORE_MEMBERS}/${id}`,
					}
				},

				invalidatesTags: (ok) => (ok ? [TAG_APP_USERS, TAG_APP_USER, TAG_STORE] : []),
			}),
		}
	},
})
