import AddPhotoAlternate from "@mui/icons-material/AddPhotoAlternate"
import Delete from "@mui/icons-material/Delete"
import { Box, CardMedia, Grid, IconButton } from "@mui/material"
import { ItemWrapper } from "components/Form"
import { Input, handleFileInput } from "components/ImageUpload"
import { useState } from "react"
import { PhotoProvider, PhotoView } from "react-photo-view"
import "react-photo-view/dist/react-photo-view.css"
import { promotionsApi } from "services/modules"
import { handlePromise } from "utils/promiseHandler"

function PhotoViewer({
	t,
	methods: {
		setValue,
		getValues,
		trigger,
		formState: { errors },
	},
}) {
	const [hover, setHover] = useState()

	const photos = getValues("photos") || []

	const [deletePhoto, { isLoading, originalArgs }] = handlePromise(
		promotionsApi.useDeletePhotoMutation,
		["successful.item", { value: "$t(successful.deleted)" }],
		["successful.item", { value: "$t(failed.delete)" }]
	)

	const updatePhotos = async (files) => {
		const newPhotos = await handleFileInput(files)

		setValue("photos", [
			...photos,
			...newPhotos.map((photo) => ({
				full: photo,
				thumbnail: photo,
			})),
		])
		trigger("photos")
	}

	return (
		<PhotoProvider
			maskOpacity={0.5}
			loop={photos.length > 1} // bug in lib, if loop is true & photos.length > 1, toolbar actions doesn't work
			loadingElement={t("Loading ...")}
			toolbarRender={({ rotate, onRotate, onScale, scale }) => (
				<>
					<svg
						className="PhotoView-Slider__toolbarIcon"
						width="44"
						height="44"
						viewBox="0 0 768 768"
						fill="white"
						onClick={() => onScale(scale + 0.5)}
					>
						<path d="M384 640.5q105 0 180.75-75.75t75.75-180.75-75.75-180.75-180.75-75.75-180.75 75.75-75.75 180.75 75.75 180.75 180.75 75.75zM384 64.5q132 0 225.75 93.75t93.75 225.75-93.75 225.75-225.75 93.75-225.75-93.75-93.75-225.75 93.75-225.75 225.75-93.75zM415.5 223.5v129h129v63h-129v129h-63v-129h-129v-63h129v-129h63z" />
					</svg>
					<svg
						className="PhotoView-Slider__toolbarIcon"
						width="44"
						height="44"
						viewBox="0 0 768 768"
						fill="white"
						onClick={() => onScale(scale - 0.5)}
					>
						<path d="M384 640.5q105 0 180.75-75.75t75.75-180.75-75.75-180.75-180.75-75.75-180.75 75.75-75.75 180.75 75.75 180.75 180.75 75.75zM384 64.5q132 0 225.75 93.75t93.75 225.75-93.75 225.75-225.75 93.75-225.75-93.75-93.75-225.75 93.75-225.75 225.75-93.75zM223.5 352.5h321v63h-321v-63z" />
					</svg>
					<svg
						className="PhotoView-Slider__toolbarIcon"
						onClick={() => onRotate(rotate + 90)}
						width="44"
						height="44"
						fill="white"
						viewBox="0 0 768 768"
					>
						<path d="M565.5 202.5l75-75v225h-225l103.5-103.5c-34.5-34.5-82.5-57-135-57-106.5 0-192 85.5-192 192s85.5 192 192 192c84 0 156-52.5 181.5-127.5h66c-28.5 111-127.5 192-247.5 192-141 0-255-115.5-255-256.5s114-256.5 255-256.5c70.5 0 135 28.5 181.5 75z" />
					</svg>
				</>
			)}
		>
			<Grid container direction="row" spacing={1}>
				{photos.map(({ full, thumbnail, id }, index) => {
					return (
						<Grid item key={index}>
							<PhotoView src={full}>
								<div
									onMouseEnter={() => {
										setHover(index)
									}}
									onMouseLeave={() => {
										setHover()
									}}
								>
									<CardMedia
										image={thumbnail}
										sx={{
											m: 0,
											width: "100px",
											height: "100px",
											cursor: "pointer",
										}}
									>
										{hover === index && (
											<IconButton
												disabled={isLoading && originalArgs === id}
												style={{
													left: "60%",
													cursor: "default",
													backgroundColor: "red",
												}}
												onClick={async (event) => {
													event.preventDefault()
													event.stopPropagation()

													if (id) {
														await deletePhoto(id).unwrap()
													}

													const copy = [...photos]

													copy.splice(index, 1)

													setValue("photos", copy)
												}}
											>
												<Delete />
											</IconButton>
										)}
									</CardMedia>
								</div>
							</PhotoView>
						</Grid>
					)
				})}

				<IconButton
					sx={{
						ml: photos.length === 0 ? 1 : 2,
						mt: 1.2,
						width: "100px",
						height: "100px",
						border: 1,
						borderRadius: 1,
						borderColor: errors.photos ? "red" : "lightgray",
					}}
					component="label"
				>
					<Box pl={3} pt={2}>
						<AddPhotoAlternate fontSize="large" />
					</Box>

					<Input multiple handleOnChange={updatePhotos} />
				</IconButton>
			</Grid>
		</PhotoProvider>
	)
}

export function PhotoCrud({ t, methods }) {
	const errors = methods.formState.errors

	return (
		<ItemWrapper lg={12} error={errors.photos} title={t("Photos")}>
			<PhotoViewer t={t} methods={methods} />
		</ItemWrapper>
	)
}
