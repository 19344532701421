import { OMIT_ON_CLONE } from "constant"
import { omit } from "lodash"
import { api } from "services/api"
import { API_CLUB_HEADS, API_INSTOCK_HEADS } from "services/endpoints"
import { TAG_DRIVER_HEAD, TAG_DRIVER_HEADS } from "services/tags"

function updateInstockHeads(baseQuery, data) {
	return baseQuery({
		data,
		method: "POST",
		url: API_INSTOCK_HEADS,
	})
}

export default api("driverHeadsApi").injectEndpoints({
	endpoints(b) {
		return {
			list: b.query({
				query(params) {
					return {
						params,
						url: API_CLUB_HEADS,
					}
				},

				providesTags: [TAG_DRIVER_HEADS],
			}),

			delete: b.mutation({
				async queryFn({ id, ...data }, queryApi, extraOptions, baseQuery) {
					const { error } = await updateInstockHeads(baseQuery, data)

					if (error) {
						return { error }
					}

					return await baseQuery({
						method: "DELETE",
						url: `${API_CLUB_HEADS}/${id}`,
					})
				},

				invalidatesTags: (ok) => (ok ? [TAG_DRIVER_HEADS] : []),
			}),

			toggleField: b.mutation({
				query({ id, field, ...data }) {
					return {
						data,
						method: "POST",
						url: `${API_CLUB_HEADS}/${id}/${field}`,
					}
				},

				invalidatesTags: (ok) => (ok ? [TAG_DRIVER_HEAD, TAG_DRIVER_HEADS] : []),
			}),

			get: b.query({
				query(id) {
					return {
						url: `${API_CLUB_HEADS}/${id}`,
					}
				},

				transformResponse: ({ data: head }) => {
					return {
						...head,
						impact: head.impact ? head.impact.toLowerCase() : "",
						spin: head.spin ? head.spin.toLowerCase() : "",
						face: head.face ? head.face.toLowerCase() : "",
						brand_id: head.brand?.id,
						photo: head.photos?.path,
					}
				},

				providesTags: [TAG_DRIVER_HEAD],
			}),

			create: b.mutation({
				async queryFn({ store_ids, ...data }, queryApi, extraOptions, baseQuery) {
					const { data: response, error } = await baseQuery({
						data: omit(data, OMIT_ON_CLONE),
						method: "POST",
						url: API_CLUB_HEADS,
					})

					if (error) {
						return { error }
					}

					return await updateInstockHeads(baseQuery, {
						head_id: response.data.id,
						store_id_list: store_ids,
					})
				},

				invalidatesTags: (ok) => (ok ? [TAG_DRIVER_HEAD, TAG_DRIVER_HEADS] : []),
			}),

			update: b.mutation({
				async queryFn({ store_ids, id, ...data }, queryApi, extraOptions, baseQuery) {
					const { error } = await baseQuery({
						data,
						method: "PUT",
						url: `${API_CLUB_HEADS}/${id}`,
					})

					if (error) {
						return { error }
					}

					return await updateInstockHeads(baseQuery, {
						head_id: id,
						store_id_list: store_ids,
					})
				},

				transformResponse: ({ data }) => data,

				invalidatesTags: (ok) => (ok ? [TAG_DRIVER_HEAD, TAG_DRIVER_HEADS] : []),
			}),
		}
	},
})
