import { ImplicitLoadingButton } from "components/MDButton"
import { useTranslation } from "react-i18next"
import { authApi } from "services/modules"
import { handleError } from "utils/promiseHandler"

function Logout() {
	const { t } = useTranslation()

	const [trigger] = handleError(authApi.useLogoutMutation)

	return (
		<ImplicitLoadingButton
			component="button"
			onClick={trigger}
			variant="gradient"
			color="secondary"
			fullWidth
		>
			{t("logout.title")}
		</ImplicitLoadingButton>
	)
}

export default Logout
