import { OMIT_ON_CLONE } from "constant"
import omit from "lodash/omit"
import { api } from "services/api"
import {
	API_ANALYZERS_ALL,
	API_STORES,
	API_STORES_LIST,
	API_STORE_COPY,
	API_USERS,
} from "services/endpoints"
import {
	TAG_ANALYZERS_ALL,
	TAG_APP_USERS,
	TAG_BODYANALYSIS_DETAILS,
	TAG_BODYANALYSIS_LIST,
	TAG_MATCH_HISTORIES,
	TAG_MATCH_HISTORY,
	TAG_STORE,
	TAG_STORES,
} from "services/tags"

export const MANUAL_ANALYZER_INPUT = 0

export const storesApi = api("storesApi").injectEndpoints({
	endpoints(build) {
		return {
			list: build.query({
				query(params) {
					return {
						params,
						method: "GET",
						url: API_STORES,
					}
				},

				providesTags: [TAG_STORES],
			}),

			listAll: build.query({
				query() {
					return {
						url: API_STORES_LIST,
					}
				},

				providesTags: [TAG_STORES],

				transformResponse: ({ data }) =>
					data.map(({ id, name }) => ({
						id,
						name,
						value: id,
					})),
			}),

			listUnregistered: build.query({
				query(params) {
					return {
						url: `${API_USERS}/${params.user_id}/unregistered-stores`,
					}
				},

				providesTags: [TAG_STORES],

				transformResponse: ({ data }) =>
					data.map(({ id, name }) => ({
						id,
						name,
						value: id,
					})),
			}),

			listFitters: build.query({
				query(params) {
					return {
						url: `${API_STORES}/${params.store_id}/fitters`,
					}
				},

				providesTags: [TAG_STORES],

				transformResponse: ({ data }) =>
					data.map(({ id, name }) => ({
						id,
						name,
						value: id,
					})),
			}),

			listAnalyzer: build.query({
				query() {
					return {
						url: API_ANALYZERS_ALL,
					}
				},

				transformResponse: ({ data }) => data.filter(({ is_official }) => is_official),

				providesTags: [TAG_ANALYZERS_ALL],
			}),

			get: build.query({
				query(id) {
					return {
						method: "GET",
						url: `${API_STORES}/${id}`,
					}
				},

				providesTags: [TAG_STORE],

				transformResponse: ({ data }) => {
					const { analyzer, storeMembers, ...response } = data

					if (analyzer) {
						response.analyzer_id = analyzer.is_official ? analyzer.id : MANUAL_ANALYZER_INPUT
						response.analyzer_value = analyzer.name
					}

					if (storeMembers) {
						response.storeMembers = storeMembers.map((member) => {
							const { appUser, ...item } = member

							const ret = {
								...appUser,
								...item,
								store_id: response.id,
								store_name: response.name,
								app_user_id: appUser.id,
								app_user: appUser.name,
								// for select input
								name: appUser.name,
								value: appUser.id,
							}

							return ret
						})
					} else {
						response.storeMembers = []
					}

					return response
				},
			}),

			deleteStores: build.mutation({
				query(id) {
					return {
						method: "DELETE",
						url: `${API_STORES}/${id}`,
					}
				},

				invalidatesTags: (ok) => (ok ? [TAG_STORES, TAG_APP_USERS] : []),
			}),

			update: build.mutation({
				query({ id, ...data }) {
					return {
						data,
						method: "PUT",
						url: `${API_STORES}/${id}`,
					}
				},

				invalidatesTags: (ok) =>
					ok
						? [
								TAG_STORE,
								TAG_STORES,
								TAG_APP_USERS,
								TAG_MATCH_HISTORY,
								TAG_MATCH_HISTORIES,
								TAG_BODYANALYSIS_DETAILS,
								TAG_BODYANALYSIS_LIST,
						  ]
						: [],
			}),

			create: build.mutation({
				query(data) {
					return {
						data: omit(data, OMIT_ON_CLONE),
						method: "POST",
						url: API_STORES,
					}
				},

				invalidatesTags: (ok) => (ok ? [TAG_STORES, TAG_APP_USERS] : []),
			}),

			copy: build.mutation({
				query(data) {
					return {
						data: omit(data, OMIT_ON_CLONE),
						method: "POST",
						url: API_STORE_COPY,
					}
				},

				invalidatesTags: (ok) => (ok ? [TAG_STORES] : []),
			}),
		}
	},
})
