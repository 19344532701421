import MDTypography from "components/MDTypography"
import React from "react"

const styles = {
	fontSize: "12px",
	marginBottom: "5px",
}

function FormLabel({ children, ...props }) {
	return (
		<MDTypography {...styles} {...props}>
			{children}
		</MDTypography>
	)
}

export default FormLabel
