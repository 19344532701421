import Icon from "@mui/material/Icon"
import MDButton from "components/MDButton"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

function BackButton({ to = -1, ...props }) {
	const { t } = useTranslation("translation")
	const navigate = useNavigate()

	return (
		<MDButton
			startIcon={<Icon>arrow_back</Icon>}
			variant="gradient"
			color="info"
			onClick={() => navigate(to)}
			{...props}
		>
			{t("common.back")}
		</MDButton>
	)
}

export default BackButton
