import Box from "@mui/material/Box"
import BackButton from "common/BackButton"

function BasePaper({ children, bg = true, ...props }) {
	const sx = { p: 2, borderRadius: 2 }

	if (bg) {
		sx.backgroundColor = "background.paper"
	}

	return (
		<Box sx={sx} {...props}>
			{children}
		</Box>
	)
}

export function LayoutWithBackButton({ children, ...props }) {
	return (
		<BasePaper {...props}>
			<BackButton sx={{ mb: 2 }} />

			{children}
		</BasePaper>
	)
}

export function LayoutPaper({ children, ...props }) {
	return (
		<BasePaper {...props} my={4}>
			{children}
		</BasePaper>
	)
}
