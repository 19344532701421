import DashboardNavbar from "common/DashboardNavbar"
import FormSnackbar from "components/FormSnackbar"
import MDBox from "components/MDBox"
import Sidenav from "components/Sidenav"
import { setMiniSidenav, useMaterialUIController } from "context"
import Login from "layouts/authentication/login"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import {
	Outlet,
	RouterProvider,
	createBrowserRouter,
	useLocation,
	useNavigate,
} from "react-router-dom"
import appRoutes from "routes"
import { useUser } from "stores/user"
import { ROUTE_DEFAULT, ROUTE_LOGIN, ROUTE_PURCHASE } from "urls"

export default function App() {
	const user = useUser()

	return (
		<RouterProvider
			router={createBrowserRouter([
				{
					path: ROUTE_LOGIN,
					element: user.id === null ? <Login /> : <Redirect />,
				},
				{
					path: "*",
					element: (
						<>
							{user.id === null ? <Login /> : <Redirect />}
							<FormSnackbar />
						</>
					),
				},
				{
					path: "/",
					element: <Root />,
					children: getRoutes(),
				},
			])}
		/>
	)
}

function getRoutes() {
	const routes = []
	const user = useUser()

	function inner(innerRoutes) {
		for (let { path, lazy, ...route } of innerRoutes) {
			if (route.access.includes(user.role)) {
				if (Array.isArray(route.children)) {
					inner(route.children)
				}

				if (Array.isArray(route.routes)) {
					inner(route.routes)
				}

				if (!path) {
					continue
				}

				routes.push({ path, lazy })
			}
		}
	}

	inner(appRoutes)

	return routes
}

function Redirect() {
	const user = useUser()

	const navigate = useNavigate()
	const { pathname } = useLocation()

	useEffect(() => {
		let defaultRoute

		if (!user || !user.role) {
			defaultRoute = ROUTE_LOGIN
		} else if (user.role === "admin") {
			defaultRoute = ROUTE_DEFAULT
		} else {
			defaultRoute = ROUTE_PURCHASE
		}

		navigate(defaultRoute)
	}, [pathname, user])

	return null
}

function Root() {
	const user = useUser()
	const [controller, dispatch] = useMaterialUIController()
	const language = useSelector((state) => state.app.language)
	const { miniSidenav, direction, sidenavColor } = controller
	const [onMouseEnter, setOnMouseEnter] = useState(false)

	const navigate = useNavigate()
	const { i18n } = useTranslation()
	const { pathname } = useLocation()

	// Open sidenav when mouse enter on mini sidenav
	const handleOnMouseEnter = () => {
		if (miniSidenav && !onMouseEnter) {
			setMiniSidenav(dispatch, false)
			setOnMouseEnter(true)
		}
	}

	// Close sidenav when mouse leave mini sidenav
	const handleOnMouseLeave = () => {
		if (onMouseEnter) {
			setMiniSidenav(dispatch, true)
			setOnMouseEnter(false)
		}
	}

	useEffect(() => {
		if (language !== i18n.language) {
			i18n.changeLanguage(language)
		}
	}, [])

	// Setting the dir attribute for the body element
	useEffect(() => {
		document.body.setAttribute("dir", direction)
	}, [direction])

	// Setting page scroll to 0 when changing the route
	useEffect(() => {
		document.documentElement.scrollTop = 0
		document.scrollingElement.scrollTop = 0
	}, [pathname])

	useEffect(() => {
		if (user.id === null) {
			return navigate(ROUTE_LOGIN)
		}

		if (pathname === "/") {
			return navigate(ROUTE_DEFAULT)
		}
	}, [pathname, user])

	return (
		<MDBox id="app-layout" sx={{ display: "flex" }}>
			{user.id !== null && (
				<Sidenav
					color={sidenavColor}
					onMouseEnter={handleOnMouseEnter}
					onMouseLeave={handleOnMouseLeave}
				/>
			)}

			<MDBox id="main-layout" sx={{ flexGrow: 1, px: 5, pb: 5, overflow: "auto" }}>
				<DashboardNavbar />
				<Outlet />
			</MDBox>

			<FormSnackbar />
		</MDBox>
	)
}
