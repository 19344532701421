import Icon from "@mui/material/Icon"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import MDBox from "components/MDBox"
import { useMaterialUIController } from "context"
import PropTypes from "prop-types"
import { NavLink } from "react-router-dom"

import { collapseIconBox, collapseItem, collapseText } from "./styles/sidenavCollapse"

function SidenavCollapse({ icon, name, active, endIcon, onClick, ...rest }) {
	const [controller] = useMaterialUIController()
	const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller

	return (
		<NavLink to={rest.path} end>
			<ListItem onClick={onClick} component="li">
				<MDBox
					{...rest}
					sx={(theme) =>
						collapseItem(theme, {
							active,
							transparentSidenav,
							whiteSidenav,
							darkMode,
							sidenavColor,
						})
					}
				>
					<ListItemIcon
						sx={(theme) =>
							collapseIconBox(theme, {
								transparentSidenav,
								whiteSidenav,
								darkMode,
								active,
							})
						}
					>
						{typeof icon === "string" ? <Icon>{icon}</Icon> : icon}
					</ListItemIcon>

					<ListItemText
						primary={name}
						sx={(theme) =>
							collapseText(theme, {
								miniSidenav,
								transparentSidenav,
								whiteSidenav,
								active,
							})
						}
					/>

					{endIcon}
				</MDBox>
			</ListItem>
		</NavLink>
	)
}

// Setting default values for the props of SidenavCollapse
SidenavCollapse.defaultProps = {
	active: false,
}

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
	icon: PropTypes.node.isRequired,
	name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
	active: PropTypes.bool,
	endIcon: PropTypes.node,
	onClick: PropTypes.func,
}

export default SidenavCollapse
