import { api } from "services/api"
import { API_DEVICES } from "services/endpoints"

export default api("devicesApi").injectEndpoints({
	endpoints(b) {
		return {
			delete: b.mutation({
				query(id) {
					return {
						method: "DELETE",
						url: `${API_DEVICES}/${id}`,
					}
				},
			}),
		}
	},
})
