import omit from "lodash/omit"
import { useSelector } from "react-redux"

const REPLACE = "REPLACE"

const CLEAR = "CLEAR"

const CLEAR_ALL = "CLEAR_ALL"

const REMOVE = "REMOVE"

const INSERT = "INSERT"

const initialState = {
	q: "",
	sort: "",
	order: "",
}

export default function reducer() {
	return (state, { type, key, value, fields }) => {
		switch (type) {
			case REPLACE:
				return Object.assign({ ...state }, { [key]: { ...value } })

			case INSERT:
				return Object.assign({ ...state }, { [key]: { ...state[key], ...value } })

			case CLEAR:
				return omit(state, key)

			case CLEAR_ALL:
				return {}

			case REMOVE: {
				const next = { ...state }

				next[key] = omit(next[key], fields)

				return { ...next }
			}

			default:
				return { ...state }
		}
	}
}

function dispatch(type, value) {
	return (dispatch) => {
		return dispatch({ type, ...value })
	}
}

export const replace = (value, key = location.pathname) => dispatch(REPLACE, { key, value })

export const clear = (key = location.pathname) => dispatch(CLEAR, { key })

export const insert = (value, key = location.pathname) => dispatch(INSERT, { key, value })

export const clearAll = () => dispatch(CLEAR_ALL)

export const remove = (fields, key = location.pathname) => dispatch(REMOVE, { key, fields })

export function get(key = location.pathname) {
	const store = useSelector((state) => state.list)

	return store[key] || initialState
}
