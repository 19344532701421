import { api } from "services/api"
import { API_STORES_HELP, API_STORES_HELP_ANSWER } from "services/endpoints"
import { TAG_STORE_HELP, TAG_STORE_HELP_LIST } from "services/tags"

export default api("adminHelpApi").injectEndpoints({
	endpoints(build) {
		return {
			list: build.query({
				query(params) {
					return {
						params,
						url: API_STORES_HELP,
					}
				},

				providesTags: [TAG_STORE_HELP_LIST],
			}),

			get: build.query({
				query(id) {
					return {
						url: `${API_STORES_HELP}/${id}`,
					}
				},

				transformResponse: ({ data }) => {
					data.photos = data.photos.map((photo) => ({
						...photo,
						thumbnail: photo.thumbnails[0]?.path,
					}))

					return data
				},

				providesTags: [TAG_STORE_HELP],
			}),

			saveAnswer: build.mutation({
				query({ id, ...data }) {
					return {
						data,
						method: "PUT",
						url: API_STORES_HELP_ANSWER(id),
					}
				},

				invalidatesTags: (ok) => (ok ? [TAG_STORE_HELP_LIST, TAG_STORE_HELP] : []),
			}),
		}
	},
})
