import { commonApi } from "services/api"
import { API_CLUB_HEADS_ALL } from "services/endpoints"
import { TAG_CLUB_HEADS_ALL } from "services/tags"

export default commonApi.injectEndpoints({
	endpoints(build) {
		return {
			getClubsHeadsAll: build.query({
				query() {
					return {
						url: API_CLUB_HEADS_ALL,
					}
				},

				providesTags: [TAG_CLUB_HEADS_ALL],
			}),
		}
	},
})
