import { api } from "services/api"
import { API_BODYANALYSIS } from "services/endpoints"
import { TAG_BODYANALYSIS_DETAILS, TAG_BODYANALYSIS_LIST } from "services/tags"

export default api("bodyAnalysisApi").injectEndpoints({
	endpoints(b) {
		return {
			list: b.query({
				query(params) {
					return {
						params,
						url: API_BODYANALYSIS,
					}
				},

				providesTags: [TAG_BODYANALYSIS_LIST],
			}),

			get: b.query({
				query(id) {
					return {
						url: `${API_BODYANALYSIS}/${id}`,
					}
				},

				providesTags: [TAG_BODYANALYSIS_DETAILS],
			}),
		}
	},
})
