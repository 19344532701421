export const ROUTE_CUSTOMERS = "/customers"
export const ROUTE_CUSTOMERS_CREATE = `${ROUTE_CUSTOMERS}/create`
export const ROUTE_CUSTOMERS_COPY = `${ROUTE_CUSTOMERS_CREATE}/copy/:id`
export const ROUTE_CUSTOMERS_EDIT = `${ROUTE_CUSTOMERS}/edit/:id`
export const ROUTE_CUSTOMERS_DETAIL = `${ROUTE_CUSTOMERS}/detail/:id`

export const ROUTE_MATCH_HISTORIES = "/match-histories"
export const ROUTE_MATCH_HISTORIES_FITTING = `${ROUTE_MATCH_HISTORIES}/:id`

export const ROUTE_BRANDS = "/brands"
export const ROUTE_BRANDS_CREATE = `${ROUTE_BRANDS}/create`
export const ROUTE_BRANDS_COPY = `${ROUTE_BRANDS_CREATE}/copy/:id`
export const ROUTE_BRANDS_EDIT = `${ROUTE_BRANDS}/edit/:id`

export const ROUTE_PUTTER = "/putter"

export const ROUTE_PUTTER_HEADS = `${ROUTE_PUTTER}/heads`
export const ROUTE_PUTTER_HEADS_CREATE = `${ROUTE_PUTTER_HEADS}/create`
export const ROUTE_PUTTER_HEADS_COPY = `${ROUTE_PUTTER_HEADS_CREATE}/copy/:id`
export const ROUTE_PUTTER_HEADS_EDIT = `${ROUTE_PUTTER_HEADS}/edit/:id`

export const ROUTE_PUTTER_SHAFTS = `${ROUTE_PUTTER}/shafts`
export const ROUTE_PUTTER_SHAFTS_CREATE = `${ROUTE_PUTTER_SHAFTS}/create`
export const ROUTE_PUTTER_SHAFTS_COPY = `${ROUTE_PUTTER_SHAFTS_CREATE}/copy/:id`
export const ROUTE_PUTTER_SHAFTS_EDIT = `${ROUTE_PUTTER_SHAFTS}/edit/:id`

export const ROUTE_DRIVER = "/driver"

export const ROUTE_DRIVER_HEADS = `${ROUTE_DRIVER}/heads`
export const ROUTE_DRIVER_HEADS_CREATE = `${ROUTE_DRIVER_HEADS}/create`
export const ROUTE_DRIVER_HEADS_COPY = `${ROUTE_DRIVER_HEADS_CREATE}/copy/:id`
export const ROUTE_DRIVER_HEADS_EDIT = `${ROUTE_DRIVER_HEADS}/edit/:id`

export const ROUTE_DRIVER_SHAFTS = `${ROUTE_DRIVER}/shafts`
export const ROUTE_DRIVER_SHAFTS_CREATE = `${ROUTE_DRIVER_SHAFTS}/create`
export const ROUTE_DRIVER_SHAFTS_COPY = `${ROUTE_DRIVER_SHAFTS_CREATE}/copy/:id`
export const ROUTE_DRIVER_SHAFTS_EDIT = `${ROUTE_DRIVER_SHAFTS}/edit/:id`

export const ROUTE_BALLS = "/balls"
export const ROUTE_BALLS_CREATE = `${ROUTE_BALLS}/create`
export const ROUTE_BALLS_EDIT = `${ROUTE_BALLS}/edit/:id`

export const ROUTE_STORES = "/stores"
export const ROUTE_STORES_CREATE = `${ROUTE_STORES}/create`
export const ROUTE_STORES_COPY = `${ROUTE_STORES_CREATE}/copy/:id`
export const ROUTE_STORES_EDIT = `${ROUTE_STORES}/edit/:id`

export const ROUTE_PURCHASE = "/purchase-history"
export const ROUTE_PURCHASE_NUMBER = `${ROUTE_PURCHASE}/:id`

export const ROUTE_USERS = "/users"
export const ROUTE_APP_USERS = `${ROUTE_USERS}/app`
export const ROUTE_APP_USERS_CREATE = `${ROUTE_APP_USERS}/create`
export const ROUTE_APP_USERS_COPY = `${ROUTE_APP_USERS_CREATE}/copy/:id`
export const ROUTE_APP_USERS_EDIT = `${ROUTE_APP_USERS}/edit/:id`

export const ROUTE_ADMIN = `${ROUTE_USERS}/admin`
export const ROUTE_ADMIN_CREATE = `${ROUTE_ADMIN}/create`
export const ROUTE_ADMIN_EDIT = `${ROUTE_ADMIN}/edit/:id`

export const ROUTE_SETTINGS = "/settings"

export const ROUTE_PLAYGROUND = "/playground"

export const ROUTE_LOGIN = "/login"

export const ROUTE_IRON = "/iron"
export const ROUTE_IRON_HEADS = `${ROUTE_IRON}/heads`
export const ROUTE_IRON_HEAD_EDIT = `${ROUTE_IRON_HEADS}/edit/:id`
export const ROUTE_IRON_HEAD_CREATE = `${ROUTE_IRON_HEADS}/create`
export const ROUTE_IRON_HEAD_COPY = `${ROUTE_IRON_HEAD_CREATE}/copy/:id`

export const ROUTE_IRON_SHAFTS = `${ROUTE_IRON}/shafts`
export const ROUTE_IRON_SHAFT_CREATE = `${ROUTE_IRON_SHAFTS}/create`
export const ROUTE_IRON_SHAFT_EDIT = `${ROUTE_IRON_SHAFTS}/edit/:id`
export const ROUTE_IRON_SHAFT_COPY = `${ROUTE_IRON_SHAFT_CREATE}/copy/:id`

export const ROUTE_IRON_SETS = "/iron-sets"
export const ROUTE_IRON_SETS_EDIT = `${ROUTE_IRON_SETS}/edit/:id`
export const ROUTE_IRON_SETS_COPY = `${ROUTE_IRON_SETS}/copy/:id`
export const ROUTE_IRON_SETS_CREATE = `${ROUTE_IRON_SETS}/create`

export const ROUTE_WEDGE = "/wedge"
export const ROUTE_WEDGE_HEADS = `${ROUTE_WEDGE}/heads`
export const ROUTE_WEDGE_HEAD_EDIT = `${ROUTE_WEDGE_HEADS}/edit/:id`
export const ROUTE_WEDGE_HEAD_CREATE = `${ROUTE_WEDGE_HEADS}/create`
export const ROUTE_WEDGE_HEAD_COPY = `${ROUTE_WEDGE_HEAD_CREATE}/copy/:id`

export const ROUTE_WEDGE_SHAFTS = `${ROUTE_WEDGE}/shafts`
export const ROUTE_WEDGE_SHAFT_CREATE = `${ROUTE_WEDGE_SHAFTS}/create`
export const ROUTE_WEDGE_SHAFT_EDIT = `${ROUTE_WEDGE_SHAFTS}/edit/:id`
export const ROUTE_WEDGE_SHAFT_COPY = `${ROUTE_WEDGE_SHAFT_CREATE}/copy/:id`

export const ROUTE_BODYANALYSIS = "/bodyanalysis"
export const ROUTE_BODYANALYSIS_DETAILS = `${ROUTE_BODYANALYSIS}/:id`

export const ROUTE_PAYMENTS = "/payments"
export const ROUTE_PAYMENTS_DETAILS = `${ROUTE_PAYMENTS}/:id`

export const ROUTE_GLOVES = "/gloves"
export const ROUTE_GLOVES_EDIT = `${ROUTE_GLOVES}/edit/:id`
export const ROUTE_GLOVES_COPY = `${ROUTE_GLOVES}/copy/:id`
export const ROUTE_GLOVES_CREATE = `${ROUTE_GLOVES}/create`

export function urlOfRoute(url, replacements = {}) {
	const parsed = url
		.split("/")
		.filter((part) => part)
		.map((part) => (part.startsWith(":") ? replacements[part.slice(1)] ?? part : part))
		.join("/")

	return `/${parsed}`
}

export const ROUTE_DEFAULT = ROUTE_CUSTOMERS

export const ROUTE_HYBRID_HEADS = "/hybrid-heads"
export const ROUTE_HYBRID_HEADS_EDIT = `${ROUTE_HYBRID_HEADS}/edit/:id`
export const ROUTE_HYBRID_HEADS_COPY = `${ROUTE_HYBRID_HEADS}/copy/:id`
export const ROUTE_HYBRID_HEADS_CREATE = `${ROUTE_HYBRID_HEADS}/create`

export const ROUTE_HYBRID_SHAFTS = "/hybrid-shafts"
export const ROUTE_HYBRID_SHAFTS_EDIT = `${ROUTE_HYBRID_SHAFTS}/edit/:id`
export const ROUTE_HYBRID_SHAFTS_COPY = `${ROUTE_HYBRID_SHAFTS}/copy/:id`
export const ROUTE_HYBRID_SHAFTS_CREATE = `${ROUTE_HYBRID_SHAFTS}/create`

export const ROUTE_WOOD_HEADS = "/wood-heads"
export const ROUTE_WOOD_HEADS_EDIT = `${ROUTE_WOOD_HEADS}/edit/:id`
export const ROUTE_WOOD_HEADS_COPY = `${ROUTE_WOOD_HEADS}/copy/:id`
export const ROUTE_WOOD_HEADS_CREATE = `${ROUTE_WOOD_HEADS}/create`

export const ROUTE_WOOD_SHAFTS = "/wood-shafts"
export const ROUTE_WOOD_SHAFTS_EDIT = `${ROUTE_WOOD_SHAFTS}/edit/:id`
export const ROUTE_WOOD_SHAFTS_COPY = `${ROUTE_WOOD_SHAFTS}/copy/:id`
export const ROUTE_WOOD_SHAFTS_CREATE = `${ROUTE_WOOD_SHAFTS}/create`

const ROUTE_HELP = "/help"
export const ROUTE_HELP_ADMIN = `${ROUTE_HELP}/admin`
export const ROUTE_HELP_ADMIN_DETAIL = `${ROUTE_HELP_ADMIN}/:id`
export const ROUTE_HELP_APP = `${ROUTE_HELP}/app`
export const ROUTE_HELP_APP_DETAIL = `${ROUTE_HELP_APP}/:id`

export const ROUTE_RESERVATIONS = "/reservations"
export const ROUTE_RESERVATION_DETAILS = `${ROUTE_RESERVATIONS}/details/:id`

export const ROUTE_BODY_TYPES = "/body-types"
export const ROUTE_BODY_TYPES_DETAIL = `${ROUTE_BODY_TYPES}/detail/:id`

export const ROUTE_PROMOTIONS = "/promotions"
export const ROUTE_PROMOTIONS_EDIT = `${ROUTE_PROMOTIONS}/edit/:id`
export const ROUTE_PROMOTIONS_CREATE = `${ROUTE_PROMOTIONS}/create`

export const ROUTE_FITTING_MANAGEMENTS = "/fittingmanagements"
export const ROUTE_FITTING_MANAGEMENTS_DETAIL = `${ROUTE_FITTING_MANAGEMENTS}/detail/:id`

export const ROUTE_RECOMMENDED_SPEC_CALCULATOR = "/recommended-spec-calculator"
