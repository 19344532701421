import isObject from "lodash/isObject"
import omit from "lodash/omit"
import { api } from "services/api"
import { API_GLOVES, API_GLOVES_INSTOCK } from "services/endpoints"
import { TAG_GLOVE, TAG_GLOVES } from "services/tags"

export function wrap(fn) {
	return async (args, queryApi, extraOptions, baseQuery) => {
		const request = isObject(args) ? omit(args, ["store_ids"]) : args

		const { error } = await baseQuery(fn(request))

		if (error) {
			return { error }
		}

		return baseQuery({
			data: {
				glove_id: args.id || args,
				store_id_list: args.store_ids || [],
			},
			method: "POST",
			url: API_GLOVES_INSTOCK,
		})
	}
}

export const glovesApi = api("glovesApi").injectEndpoints({
	endpoints(b) {
		return {
			all: b.query({
				query(params) {
					return {
						params,
						url: API_GLOVES,
					}
				},

				providesTags: [TAG_GLOVES],
			}),

			create: b.mutation({
				async queryFn({ store_ids, ...data }, queryApi, extraOptions, baseQuery) {
					const { error, data: response } = await baseQuery({
						data,
						method: "POST",
						url: API_GLOVES,
					})

					if (error) {
						return { error }
					}

					return baseQuery({
						data: { store_id_list: store_ids, glove_id: response.data.id },
						method: "POST",
						url: API_GLOVES_INSTOCK,
					})
				},

				invalidatesTags: (ok) => (ok ? [TAG_GLOVES] : []),
			}),

			toggleField: b.mutation({
				query({ id, field, ...data }) {
					return {
						data,
						method: "POST",
						url: `${API_GLOVES}/${id}/${field}`,
					}
				},

				invalidatesTags: (ok) => (ok ? [TAG_GLOVE, TAG_GLOVES] : []),
			}),

			get: b.query({
				query(id) {
					return {
						url: `${API_GLOVES}/${id}`,
					}
				},

				transformResponse({ data }) {
					return {
						...omit(data, ["created_at", "updated_at", "stores"]),
						photo: data.photos?.path,
						brand_id: data.brand?.id,
						store_ids: data.stores?.map((store) => Number(store.id)),
					}
				},

				providesTags: [TAG_GLOVE],
			}),

			update: b.mutation({
				queryFn: wrap(function query({ id, ...data }) {
					return {
						data,
						method: "PUT",
						url: `${API_GLOVES}/${id}`,
					}
				}),

				invalidatesTags: (ok) => (ok ? [TAG_GLOVE, TAG_GLOVES] : []),
			}),

			delete: b.mutation({
				queryFn: wrap(function query(id) {
					return {
						method: "DELETE",
						url: `${API_GLOVES}/${id}`,
					}
				}),

				invalidatesTags: (ok) => (ok ? [TAG_GLOVE, TAG_GLOVES] : []),
			}),

			list: b.query({
				query() {
					return {
						url: `${API_GLOVES}/list`,
					}
				},

				transformResponse: ({ data }) => data,
			}),
		}
	},
})
