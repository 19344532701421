import { t } from "i18next"

import { dobFormatter, formatDate } from "./dateTime"
import { formatCurrency } from "./helpers"

/* Format string as `3-4-4` */
export function formatPhoneNumber(value) {
	if (typeof value !== "string") return null

	return value.split("").reduce((init, current, index) => {
		if (index === 2 || index === 6) {
			current += "-"
		}

		init += current

		return init
	}, "")
}

// format know format into desired format intended to be used in common components
// where it renders different fields and individual field is unknown
export function wellKnown(name, value) {
	switch (name) {
		case "dob":
			return dobFormatter(value)

		case "created_at":
		case "updated_at":
		case /.created_at$/.test(name) ? name : Math.random():
		case /.updated_at$/.test(name) ? name : Math.random():
			return formatDate(value)

		case "phone_number":
		case /.phone_number$/.test(name) ? name : Math.random():
			return formatPhoneNumber(value)

		case "purchased_at":
			return formatDate(value)

		case "gender":
		case /.gender$/.test(name) ? name : Math.random():
			return value === 0 || value === "f" ? t("Female") : t("Male")

		default:
			return value
	}
}

export function wellKnownV2(type, value) {
	if (value === undefined || value === null) return null

	switch (type) {
		case "dob":
			return dobFormatter(value)

		case "date":
			return formatDate(value)

		case "currency":
			return `${formatCurrency(value)} ${t("KRW")}`

		default:
			return value
	}
}
