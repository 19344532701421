import { createApi } from "@reduxjs/toolkit/query/react"

import customAxios from "./customAxios"
import * as Tags from "./tags"

export const api = (reducerPath, ...options) =>
	createApi({
		reducerPath,
		// seems un-necessary to do this but we have to do this
		// if we want to preserve error respose from the server.
		// Notice the error return
		baseQuery: async (args) => {
			try {
				const { data } = await customAxios(args)

				if (data.is_success === false) return { error: data }

				return { data }
			} catch (error) {
				return { error }
			}
		},
		tagTypes: Object.values(Tags),
		endpoints: () => ({}),
		...options,
	})

export const commonApi = api("commonApi")
