import { api } from "services/api"
import { API_RESERVATIONS } from "services/endpoints"
import { TAG_RESERVATION, TAG_RESERVATIONS } from "services/tags"

export const reservationApi = api("reservationApi").injectEndpoints({
	endpoints(b) {
		return {
			list: b.query({
				query(params) {
					return {
						params,
						url: API_RESERVATIONS,
					}
				},

				providesTags: [TAG_RESERVATIONS],
			}),

			get: b.query({
				query(id) {
					return {
						url: `${API_RESERVATIONS}/${id}`,
					}
				},

				transformResponse: ({ data }) => data,

				providesTags: [TAG_RESERVATION],
			}),
		}
	},
})
