import { OMIT_ON_CLONE } from "constant"
import { omit } from "lodash"
import { api } from "services/api"
import {
	API_HYBRID_HEADS,
	API_HYBRID_HEADS_LIST,
	API_INSTOCK_HYBRID_HEADS,
} from "services/endpoints"
import { TAG_HYBRID_HEAD, TAG_HYBRID_HEADS } from "services/tags"

function updateInstockHeads(baseQuery, data) {
	return baseQuery({
		data,
		method: "POST",
		url: API_INSTOCK_HYBRID_HEADS,
	})
}

export default api("hybridHeadsApi").injectEndpoints({
	endpoints(b) {
		return {
			list: b.query({
				query(params) {
					return {
						params,
						url: API_HYBRID_HEADS,
					}
				},

				providesTags: [TAG_HYBRID_HEADS],
			}),

			getHybridHeadsList: b.query({
				query() {
					return {
						url: API_HYBRID_HEADS_LIST,
					}
				},

				providesTags: [TAG_HYBRID_HEADS],
			}),

			create: b.mutation({
				async queryFn({ store_ids, ...data }, queryApi, extraOptions, baseQuery) {
					const { data: response, error } = await baseQuery({
						data: omit(data, OMIT_ON_CLONE),
						method: "POST",
						url: API_HYBRID_HEADS,
					})

					if (error) {
						return { error }
					}

					return await updateInstockHeads(baseQuery, {
						head_id: response.data.id,
						store_id_list: store_ids,
					})
				},

				invalidatesTags: [TAG_HYBRID_HEADS],
			}),

			get: b.query({
				query(id) {
					return {
						url: `${API_HYBRID_HEADS}/${id}`,
					}
				},

				transformResponse({ data: { brand, ...data } }) {
					return {
						...data,
						photo: data.photos?.path,
						brand_id: brand?.id,
					}
				},

				providesTags: [TAG_HYBRID_HEAD],
			}),

			update: b.mutation({
				async queryFn({ store_ids, id, ...data }, queryApi, extraOptions, baseQuery) {
					const { error } = await baseQuery({
						data,
						method: "PUT",
						url: `${API_HYBRID_HEADS}/${id}`,
					})

					if (error) {
						return { error }
					}

					return await updateInstockHeads(baseQuery, {
						head_id: id,
						store_id_list: store_ids,
					})
				},

				invalidatesTags: [TAG_HYBRID_HEAD, TAG_HYBRID_HEADS],
			}),

			delete: b.mutation({
				async queryFn(id, queryApi, extraOptions, baseQuery) {
					const { error } = await updateInstockHeads(baseQuery, { head_id: id, store_id_list: [] })

					if (error) {
						return { error }
					}

					return await baseQuery({
						method: "DELETE",
						url: `${API_HYBRID_HEADS}/${id}`,
					})
				},

				invalidatesTags: [TAG_HYBRID_HEADS],
			}),
		}
	},
})
