export const TAG_BRAND = "Brand"
export const TAG_BRANDS = "Brands"
export const TAG_BRANDS_ALL = "BrandsAll"

export const TAG_APP_USER = "AppUser"
export const TAG_APP_USERS = "AppUsers"
export const TAG_APP_USERS_ALL = "AppUsersAll"

export const TAG_ADMIN_USER = "AdminUser"
export const TAG_ADMIN_USERS = "AdminUsers"

export const TAG_PURCHASE_HISTORY = "PurchaseHistory"
export const TAG_PURCHASE_HISTORY_COMMENT = "PurchaseHistoryComment"
export const TAG_PURCHASE_HISTORY_COMMENTS = "PurchaseHistoryComments"

export const TAG_STORE = "Store"
export const TAG_STORES = "Stores"
export const TAG_STORE_ROOMS = "StoresRooms"
export const TAG_STORE_HELP = "StoreHelp"
export const TAG_STORE_HELP_LIST = "StoreHelpList"

export const TAG_ANALYZERS_ALL = "AnalyzersAll"

export const TAG_IRON_HEAD = "IronHead"
export const TAG_IRON_HEADS = "IronHeads"
export const TAG_IRON_HEADS_ALL = "IronHeadsAll"

export const TAG_IRON_SET = "Set"
export const TAG_IRON_SETS = "Sets"
export const TAG_IRON_SETS_CLUB_HEADS = "SetsClubHeads"
export const TAG_IRON_SETS_WEDGE_HEADS = "SetsWedgeHeads"

export const TAG_IRON_SHAFT = "IronShaft"
export const TAG_IRON_SHAFTS = "IronShafts"
export const TAG_IRON_SHAFTS_ALL = "IronHeadsAll"

export const TAG_PUTTER_HEAD = "PutterHead"
export const TAG_PUTTER_HEADS = "PutterHeads"
export const TAG_PUTTER_HEAD_LIST = "PutterHeadList"

export const TAG_PUTTER_SHAFT = "PutterShaft"
export const TAG_PUTTER_SHAFTS = "PutterShafts"

export const TAG_CLUB_HEADS_ALL = "ClubHeadsAll"

export const TAG_SHAFTS_ALL = "ShaftsAll"

export const TAG_TRAINERS_ALL = "TrainersAll"

export const TAG_DRIVER_HEAD = "DriverHead"
export const TAG_DRIVER_HEADS = "DriverHeads"

export const TAG_WEDGE_HEAD = "WedgeHead"
export const TAG_WEDGE_HEADS = "WedgeHeads"

export const TAG_WEDGE_SHAFT = "WedgeShaft"
export const TAG_WEDGE_SHAFTS = "WedgeShafts"

export const TAG_DRIVER_SHAFT = "DriverShaft"
export const TAG_DRIVER_SHAFTS = "DriverShafts"
export const TAG_DRIVER_SHAFT_FLEXES = "DriverShaftFlexes"

export const TAG_BALL = "BallInfo"
export const TAG_BALLS = "BallsInfo"

export const TAG_CUSTOMER = "Customer"
export const TAG_CUSTOMERS = "Customers"

export const TAG_CUSTOMER_SHIPPING_ADDRESS = "CustomerShippingAddress"
export const TAG_CUSTOMER_SHIPPING_ADDRESS_LIST = "CustomerShippingAddressList"

export const TAG_STORE_CUSTOMER = "StoreCustomer"
export const TAG_STORE_CUSTOMERS = "StoreCustomers"

export const TAG_AUTH = "AuthUser"

export const TAG_SETTINGS_APPVERSION = "SettingsAppVersion"

export const TAG_BODYANALYSIS_DETAILS = "BodyAnalysis"
export const TAG_BODYANALYSIS_LIST = "BodyAnalysisList"

export const TAG_PAYMENT = "Payment"
export const TAG_PAYMENTS = "Payments"

export const TAG_MATCH_HISTORY = "MatchHistory"
export const TAG_MATCH_HISTORIES = "MatchHistories"

export const TAG_STORE_MEMBERS = "StoreMembers"

export const TAG_GLOVE = "Glove"
export const TAG_GLOVES = "Gloves"

export const TAG_HYBRID_HEAD = "HybridHead"
export const TAG_HYBRID_HEADS = "HybridHeads"

export const TAG_HYBRID_SHAFT = "HybridShaft"
export const TAG_HYBRID_SHAFTS = "HybridShafts"

export const TAG_WOOD_HEAD = "WoodHead"
export const TAG_WOOD_HEADS = "WoodHeads"

export const TAG_WOOD_SHAFT = "WoodShaft"
export const TAG_WOOD_SHAFTS = "WoodShafts"

export const TAG_VALIDATION_COMMON = "ValidationCommon"
export const TAG_VALIDATION_WEDGES = "ValidationWedges"
export const TAG_VALIDATION_DRIVERS = "ValidationDrivers"
export const TAG_VALIDATION_WOODS = "ValidationWoods"
export const TAG_VALIDATION_HYBRIDS = "ValidationHybrids"
export const TAG_VALIDATION_PUTTERS = "ValidationPutters"
export const TAG_VALIDATION_IRONS = "ValidationIrons"

export const TAG_ADMIN_HELP = "AdminHelp"
export const TAG_ADMIN_HELPS = "AdminHelps"
export const TAG_APP_HELP = "AppHelp"
export const TAG_APP_HELPS = "AppHelps"

export const TAG_RESERVATION = "Reservation"
export const TAG_RESERVATIONS = "Reservations"

export const TAG_BODY_TYPE = "BodyType"
export const TAG_BODY_TYPES = "BodyTypes"

export const TAG_PROMOTION = "Promotion"
export const TAG_PROMOTIONS = "Promotions"

export const TAG_FITTING_MANAGEMENT = "Fittingmanagement"
export const TAG_FITTING_MANAGEMENTS = "Fittingmanagements"
