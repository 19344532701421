export const SNACKBAR_OPEN = "open"
export const SNACKBAR_CLOSE = "close"

const initialState = {
	open: false,
	message: "",
	severity: "success",
	delay: 3000,
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SNACKBAR_OPEN:
			return {
				...state,
				open: true,
				severity: action.severity,
				message: action.message,
				delay: action.delay,
			}
		case SNACKBAR_CLOSE:
			return { ...state, open: false }
		default:
			return state
	}
}

export const openSnackbar =
	(severity, message, delay = initialState.delay) =>
	(dispatch) => {
		dispatch({ type: SNACKBAR_OPEN, severity, message, delay })
	}

export const closeSnackbar = () => (dispatch) => {
	dispatch({ type: SNACKBAR_CLOSE })
}

export default reducer
