import { api } from "services/api"
import { API_HELP_APP } from "services/endpoints"
import { TAG_APP_HELP, TAG_APP_HELPS } from "services/tags"

export default api("appHelpApi").injectEndpoints({
	endpoints: (b) => ({
		list: b.query({
			query(params) {
				return {
					params,
					url: API_HELP_APP,
				}
			},

			providesTags: [TAG_APP_HELPS],
		}),

		get: b.query({
			query(id) {
				return {
					url: `${API_HELP_APP}/${id}`,
				}
			},

			transformResponse: ({ data }) => data,

			providesTags: [TAG_APP_HELP],
		}),

		saveAnswer: b.mutation({
			query({ id, ...data }) {
				return {
					data,
					method: "PUT",
					url: `${API_HELP_APP}/${id}/answer`,
				}
			},

			invalidatesTags: (ok) => (ok ? [TAG_APP_HELPS, TAG_APP_HELP] : []),
		}),
	}),
})
