import { api } from "services/api"
import { API_FITTING_MANAGEMENT } from "services/endpoints"
import { TAG_FITTING_MANAGEMENT, TAG_FITTING_MANAGEMENTS } from "services/tags"

export const fittingManagementApi = api("fittingManagementApi").injectEndpoints({
	endpoints(b) {
		return {
			list: b.query({
				query(params) {
					return {
						params,
						url: API_FITTING_MANAGEMENT,
					}
				},

				providesTags: [TAG_FITTING_MANAGEMENTS],
			}),

			get: b.query({
				query(id) {
					return {
						url: `${API_FITTING_MANAGEMENT}/${id}`,
					}
				},

				providesTags: [TAG_FITTING_MANAGEMENT],
			}),
		}
	},
})
