export const KOREAN_LANGUAGE = "kr"
export const ENGLISH_LANGUAGE = "en"
export const DATE_DEFAULT_FORMAT = "yyyy-MM-dd"

export const COLORS = {
	RED: "#F44335",
	BLUE: "#2A5FD1",
	WHITE: "#FFFFFF",
	DARK: "#222222",
	LIGHTGREY: "#7B809A",
	GREY: "#344767",
}

export const PURCHASE_STATUSES = {
	ordered: "info",
	backordered: "info",
	cancelled: "error",
	preparing: "info",
	shipping: "warning",
	complete: "success",
	refunded: "default",
	payment_pending: "secondary",
	payment_cancelled: "primary",
	payment_failed: "error",
}

export const OMIT_ON_CLONE = ["id"]

export const IRON_SHAFT_MATERIALS = (t) => [
	{ name: t("Steel"), value: "Steel" },
	{ name: t("Grafite"), value: "Grafite" },
]
export const DELIVERY_METHODS = {
	parcel: "Parcel",
	store: "Store",
}

export const SHAFT_FLEX_OPTIONS = {
	left: [...Array(9)].map((_, idx) => idx + 3),
	right: ["L", "R", "SR", "S", "X", "XX"],
}

export const DRIVER_CLUB_LOFT_ANGLES = [
	{ name: 7, value: 7 },
	{ name: 7.5, value: 7.5 },
	{ name: 8, value: 8 },
	{ name: 8.5, value: 8.5 },
	{ name: 9, value: 9 },
	{ name: 9.5, value: 9.5 },
	{ name: 10, value: 10 },
	{ name: 10.5, value: 10.5 },
	{ name: 11, value: 11 },
	{ name: 11.5, value: 11.5 },
	{ name: 12, value: 12 },
	{ name: 12.5, value: 12.5 },
	{ name: 13, value: 13 },
	{ name: 13.5, value: 13.5 },
]

export const STATUS_COLOR_MAP = {
	ACTIVE: "info",
	INACTIVE: "error",
	WITHDRAWAL: "secondary",
}

export const statusColorMap = (status) => {
	const color = STATUS_COLOR_MAP[status]
	return color || "defaultColor"
}
