import { Dialog } from "@mui/material"
import useWindowWidth from "hooks/useWindowResize"
import PropTypes from "prop-types"
import DaumPostcodeEmbed from "react-daum-postcode"

const MAX_WIDTH = 540

export const PostCode = ({ isOpen, setIsOpen, onComplete }) => {
	const windowWidth = useWindowWidth()

	const handleClose = () => {
		setIsOpen(false)
	}

	const handleComplete = (data) => {
		setIsOpen(false)
		onComplete(data)
	}

	const adjustedWidth = windowWidth < MAX_WIDTH ? windowWidth - 40 : MAX_WIDTH

	return (
		<Dialog open={isOpen} onClose={handleClose}>
			<DaumPostcodeEmbed onComplete={handleComplete} style={{ width: adjustedWidth }} />
		</Dialog>
	)
}

PostCode.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	onComplete: PropTypes.func.isRequired,
}

PostCode.defaultProps = {
	isOpen: false,
	setIsOpen: () => {},
	onComplete: () => {},
}
