import { commonApi } from "services/api"
import { API_SHAFTS_LIST } from "services/endpoints"
import { TAG_SHAFTS_ALL } from "services/tags"

export default commonApi.injectEndpoints({
	endpoints(build) {
		return {
			getClubsShaftsList: build.query({
				query() {
					return {
						url: API_SHAFTS_LIST,
					}
				},

				providesTags: [TAG_SHAFTS_ALL],
			}),
		}
	},
})
