// prop-types is a library for typechecking of props
import Stack from "@mui/material/Stack"
import Switch from "@mui/material/Switch"
import Typography from "@mui/material/Typography"
// Material Dashboard 2 React components
import { styled } from "@mui/material/styles"
import PropTypes from "prop-types"

const CustomSwitch = styled(Switch)(() => ({
	width: 28,
	height: 16,
	padding: 0,
	display: "flex",
	"& .MuiSwitch-switchBase": {
		padding: 2,
		"&.Mui-checked": {
			transform: "translateX(12px)",
		},
	},
	"& .MuiSwitch-thumb": {
		width: 12,
		height: 12,
		borderRadius: 6,
	},
}))

function MDSwitch({ onChange, checked, suffixText, prefixText }) {
	return (
		<Stack direction="row" spacing={1} alignItems="center">
			<Typography fontSize={12}>{prefixText}</Typography>
			<CustomSwitch checked={checked} onChange={(e) => onChange(e)} />
			<Typography fontSize={12}>{suffixText}</Typography>
		</Stack>
	)
}

// default values for the props of MDSwitch
MDSwitch.defaultProps = {
	prefixText: "",
	suffixText: "",
}

// Typechecking props for MDSwitch
MDSwitch.propTypes = {
	onChange: PropTypes.func.isRequired,
	checked: PropTypes.bool.isRequired,
	suffixText: PropTypes.string,
	prefixText: PropTypes.string,
}

export default MDSwitch
