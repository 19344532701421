import createCache from "@emotion/cache"
import { CacheProvider } from "@emotion/react"
import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider } from "@mui/material/styles"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import theme from "assets/theme"
import { MaterialUIControllerProvider } from "context"
import { enUS, ko } from "date-fns/locale"
import { useTranslation } from "react-i18next"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/lib/integration/react"
import storeObject from "stores/store"

const cache = createCache({
	key: "css",
	prepend: true,
})

export default function Wrapper({ children }) {
	const { i18n } = useTranslation()

	return (
		<MaterialUIControllerProvider>
			<Provider store={storeObject.store}>
				<PersistGate persistor={storeObject.persistor}>
					<CacheProvider value={cache}>
						<LocalizationProvider
							dateAdapter={AdapterDateFns}
							adapterLocale={i18n.language === "en" ? enUS : ko}
						>
							<ThemeProvider theme={theme}>
								<CssBaseline />
								{children}
							</ThemeProvider>
						</LocalizationProvider>
					</CacheProvider>
				</PersistGate>
			</Provider>
		</MaterialUIControllerProvider>
	)
}
