import { commonApi } from "services/api"
import {
	API_VALIDATION_COMMON,
	API_VALIDATION_DRIVERS,
	API_VALIDATION_HYBRIDS,
	API_VALIDATION_IRONS,
	API_VALIDATION_PUTTERS,
	API_VALIDATION_WEDGES,
	API_VALIDATION_WOODS,
} from "services/endpoints"
import {
	TAG_VALIDATION_COMMON,
	TAG_VALIDATION_DRIVERS,
	TAG_VALIDATION_HYBRIDS,
	TAG_VALIDATION_IRONS,
	TAG_VALIDATION_PUTTERS,
	TAG_VALIDATION_WEDGES,
	TAG_VALIDATION_WOODS,
} from "services/tags"

export const validationApi = commonApi.injectEndpoints({
	endpoints(build) {
		return {
			getCommonValidRules: build.query({
				query(params) {
					return {
						params,
						method: "GET",
						url: API_VALIDATION_COMMON,
					}
				},

				providesTags: [TAG_VALIDATION_COMMON],
			}),

			getWedgesValidRules: build.query({
				query(params) {
					return {
						params,
						method: "GET",
						url: API_VALIDATION_WEDGES,
					}
				},

				providesTags: [TAG_VALIDATION_WEDGES],
			}),

			getDriversValidRules: build.query({
				query(params) {
					return {
						params,
						method: "GET",
						url: API_VALIDATION_DRIVERS,
					}
				},

				providesTags: [TAG_VALIDATION_DRIVERS],
			}),

			getWoodsValidRules: build.query({
				query(params) {
					return {
						params,
						method: "GET",
						url: API_VALIDATION_WOODS,
					}
				},

				providesTags: [TAG_VALIDATION_WOODS],
			}),

			getHybridsValidRules: build.query({
				query(params) {
					return {
						params,
						method: "GET",
						url: API_VALIDATION_HYBRIDS,
					}
				},

				providesTags: [TAG_VALIDATION_HYBRIDS],
			}),

			getPuttersValidRules: build.query({
				query(params) {
					return {
						params,
						method: "GET",
						url: API_VALIDATION_PUTTERS,
					}
				},

				providesTags: [TAG_VALIDATION_PUTTERS],
			}),

			getIronsValidRules: build.query({
				query(params) {
					return {
						params,
						method: "GET",
						url: API_VALIDATION_IRONS,
					}
				},

				providesTags: [TAG_VALIDATION_IRONS],
			}),
		}
	},
})

export const {
	useGetCommonValidRulesQuery,
	useGetWedgesValidRulesQuery,
	useGetDriversValidRulesQuery,
	useGetWoodsValidRulesQuery,
	useGetHybridsValidRulesQuery,
	useGetPuttersValidRulesQuery,
	useGetIronsValidRulesQuery,
} = validationApi
