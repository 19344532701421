import { api } from "services/api"
import { API_LOGIN, API_LOGOUT } from "services/endpoints"
import { TAG_AUTH } from "services/tags"

export default api("authApi").injectEndpoints({
	endpoints(b) {
		async function clear() {
			const { removeUser } = await import("stores/user")
			const { store } = await import("stores/store")

			store.dispatch(removeUser())
		}

		return {
			login: b.mutation({
				async queryFn(data, queryApi, extraOptions, baseQuery) {
					await clear()

					return await baseQuery({
						data,
						method: "POST",
						url: API_LOGIN,
					})
				},

				providesTags: (ok) => (ok ? [TAG_AUTH] : []),
			}),

			logout: b.mutation({
				async queryFn(arg, queryApi, extraOptions, baseQuery) {
					const { error, data } = await baseQuery({ method: "POST", url: API_LOGOUT })

					if (error) {
						return { error }
					}

					await clear()

					return { data }
				},

				invalidatesTags: (ok) => (ok ? [TAG_AUTH] : []),
			}),
		}
	},
})
