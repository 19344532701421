import { Alert, Snackbar, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { COLORS } from "constant"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { closeSnackbar } from "stores/snackbar"

const useStyles = makeStyles(() => ({
	customColor: {
		color: COLORS.WHITE,
	},
}))

function FormSnackbar() {
	const classes = useStyles()
	const dispatch = useDispatch()
	const state = useSelector((state) => state.snackbar)
	const onClose = () => dispatch(closeSnackbar())

	return (
		<Snackbar
			anchorOrigin={{ vertical: "top", horizontal: "center" }}
			open={state.open}
			onClose={onClose}
			autoHideDuration={state.delay}
		>
			<Alert
				severity={state.severity}
				onClose={onClose}
				variant="filled"
				className={classes.customColor}
			>
				<Typography fontSize={14} fontWeight={700} color={COLORS.WHITE}>
					{state.message}
				</Typography>
			</Alert>
		</Snackbar>
	)
}

export default FormSnackbar
