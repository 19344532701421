import { Icon } from "@mui/material"
import { ImplicitLoadingButton } from "components/MDButton"

const styles = {
	width: "200px",
	height: "45px",
	marginLeft: "auto",
	marginTop: "30px",
	display: "flex",
	position: "relative",
}

export default function FormSaveButton({
	children,
	disabled = false,
	onClick = () => {},
	...props
}) {
	return (
		<ImplicitLoadingButton
			sx={styles}
			variant="gradient"
			color="dark"
			type="submit"
			startIcon={<Icon>save</Icon>}
			disabled={disabled}
			onClick={onClick}
			{...props}
		>
			{children}
		</ImplicitLoadingButton>
	)
}
