import { api } from "services/api"
import { API_USERS } from "services/endpoints"
import { TAG_STORE_CUSTOMERS } from "services/tags"

export default api("storeCustomerApi").injectEndpoints({
	endpoints(b) {
		return {
			list: b.query({
				query(params) {
					return {
						params,
						url: `${API_USERS}/${params.user_id}/store-customers`,
					}
				},

				providesTags: [TAG_STORE_CUSTOMERS],
			}),

			create: b.mutation({
				query(data) {
					return {
						data,
						method: "POST",
						url: `${API_USERS}/${data.user_id}/store-customers`,
					}
				},

				invalidatesTags: [TAG_STORE_CUSTOMERS],
			}),

			update: b.mutation({
				query({ store_customer_id, ...data }) {
					return {
						data,
						method: "PUT",
						url: `${API_USERS}/${data.user_id}/store-customers/${store_customer_id}`,
					}
				},

				invalidatesTags: (ok) => (ok ? [TAG_STORE_CUSTOMERS] : []),
			}),
		}
	},
})
