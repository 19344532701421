import { api } from "services/api"
import { API_FITTING_HISTORIES } from "services/endpoints"
import { TAG_MATCH_HISTORIES, TAG_MATCH_HISTORY } from "services/tags"

export default api("matchHistoriesApi").injectEndpoints({
	endpoints(build) {
		return {
			fittingHistories: build.query({
				query(id) {
					return {
						method: "GET",
						url: `${API_FITTING_HISTORIES}/${id}`,
					}
				},

				providesTags: [TAG_MATCH_HISTORY],

				transformResponse: ({ data }) => {
					const hand = data.fitting.golfer_detail?.hand

					if (hand) {
						data.hand = hand === "l" ? "Left" : "Right"
					}

					return data
				},
			}),

			list: build.query({
				query(params) {
					return {
						params,
						method: "GET",
						url: API_FITTING_HISTORIES,
					}
				},

				providesTags: [TAG_MATCH_HISTORIES],
			}),

			indexByUserOrStore: build.query({
				query({ id, ...params }) {
					return {
						params,
						method: "GET",
						url: `${API_FITTING_HISTORIES}/index-by-user-or-store?user_id=${id}`,
					}
				},
			}),
		}
	},
})
