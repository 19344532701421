import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { openSnackbar } from "stores/snackbar"

export function handlePromise(
	fn,
	successMsg = "Successfully fulfilled the action",
	errorMsg = "Unable to fulfill the action",
	options
) {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const ret = fn()

	const { error, isSuccess } = Array.isArray(ret) ? ret[1] : ret

	useEffect(() => {
		if (isSuccess) {
			const successkeys = typeof successMsg === "string" ? [successMsg] : successMsg

			dispatch(openSnackbar("success", t(...successkeys)))

			options?.onSuccess?.()
		}
	}, [isSuccess])

	useEffect(() => {
		if (error) {
			const errorkeys = error.message
				? [error.message]
				: typeof errorMsg === "string"
				? [errorMsg]
				: errorMsg

			dispatch(openSnackbar("error", t(...errorkeys)))

			options?.onError?.()
		}
	}, [error])

	return ret
}

export function handleError(fn, ...failMsgKey) {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const ret = fn()

	const { error } = Array.isArray(ret) ? ret[1] : ret

	useEffect(() => {
		if (error) {
			const keys = failMsgKey.length === 0 ? ["common.error"] : failMsgKey

			dispatch(openSnackbar("error", t(...keys)))
		}
	}, [error])

	return ret
}
