import { useTranslation } from "react-i18next"
import { api } from "services/api"
import { API_BRANDS_LIST_FILTER } from "services/endpoints"
import { API_BRANDS } from "services/endpoints"
import { TAG_BRAND, TAG_BRANDS, TAG_BRANDS_ALL } from "services/tags"
import { handleError } from "utils/promiseHandler"

export function useBrandsAsField(filter) {
	const { t } = useTranslation()

	const { data = [], isFetching } = handleError(
		() => brandsApi.useListAllQuery({ filter }),
		"common.errorWith",
		{ name: "brands" }
	)

	const brands = isFetching ? [{ name: t("Loading ..."), value: 0 }] : data

	return [brands, isFetching]
}

export const brandsApi = api("brandsApi").injectEndpoints({
	endpoints(build) {
		return {
			listAll: build.query({
				query({ filter }) {
					return {
						url: `${API_BRANDS_LIST_FILTER}/${filter ?? ""}`,
					}
				},

				providesTags: [TAG_BRANDS_ALL],

				transformResponse({ data }) {
					return data.map(({ name, id }) => ({ name, value: id }))
				},
			}),

			list: build.query({
				query(params) {
					return {
						params,
						url: API_BRANDS,
					}
				},

				providesTags: [TAG_BRANDS],
			}),

			create: build.mutation({
				query(data) {
					return {
						data,
						method: "POST",
						url: API_BRANDS,
					}
				},

				providesTags: [TAG_BRAND],

				invalidatesTags: (ok) => (ok ? [TAG_BRANDS] : []),
			}),

			delete: build.mutation({
				query(id) {
					return {
						method: "DELETE",
						url: `${API_BRANDS}/${id}`,
					}
				},

				invalidatesTags: (ok) => (ok ? [TAG_BRANDS] : []),
			}),

			get: build.query({
				query(id) {
					return {
						url: `${API_BRANDS}/${id}`,
					}
				},

				transformResponse: ({ data }) => data,

				providesTags: [TAG_BRAND],
			}),

			update: build.mutation({
				query({ id, ...data }) {
					return {
						data,
						method: "PUT",
						url: `${API_BRANDS}/${id}`,
					}
				},

				invalidatesTags: (ok) => (ok ? [TAG_BRAND, TAG_BRANDS] : []),
			}),
		}
	},
})
