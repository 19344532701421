import { api } from "services/api"
import { API_STORES } from "services/endpoints"
import { TAG_STORE_ROOMS } from "services/tags"

export default api("storeRoomsApi").injectEndpoints({
	endpoints(build) {
		return {
			list: build.query({
				query(store_id) {
					return {
						url: `${API_STORES}/${store_id}/rooms`,
					}
				},

				transformResponse: ({ data }) =>
					data.map((data) => ({
						...data,
						room_id: data.id,
					})),

				providesTags: [TAG_STORE_ROOMS],
			}),

			add: build.mutation({
				query({ store_id, ...data }) {
					return {
						data,
						method: "POST",
						url: `${API_STORES}/${store_id}/rooms`,
					}
				},

				transformResponse: (data) => ({
					...data,
					room_id: data.id,
				}),

				invalidatesTags: (ok) => (ok ? [TAG_STORE_ROOMS] : []),
			}),

			save: build.mutation({
				query({ store_id, room_id, ...data }) {
					return {
						data,
						method: "PUT",
						url: `${API_STORES}/${store_id}/rooms/${room_id}`,
					}
				},
			}),

			remove: build.mutation({
				query({ store_id, room_id }) {
					return {
						method: "DELETE",
						url: `${API_STORES}/${store_id}/rooms/${room_id}`,
					}
				},

				invalidatesTags: (ok) => (ok ? [TAG_STORE_ROOMS] : []),
			}),
		}
	},
})
