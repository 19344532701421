import { LayoutWithBackButton } from "common/form/layout"
import CommonForm from "components/Form"
import { capitalize } from "lodash"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { handlePromise } from "utils/promiseHandler"
import { array, object, string } from "yup"

import { PhotoCrud } from "./photoCrud"

const req = (ty) => ty.required("common.fieldRequired")

export const formSchema = object({
	photos: req(
		array().meta({
			type: "custom",
			render: PhotoCrud,
		})
	),
	start_date: req(string().meta({ type: "date", lg: 6 })),
	end_date: req(
		string()
			.meta({ type: "date", lg: 6 })
			.test((value, ctx) => {
				const end = new Date(value)
				const start = new Date(ctx.parent.start_date)

				if (end < start) {
					return ctx.createError({
						message: "End date cannot be earlier than start date",
					})
				}

				return true
			})
	),
	url: req(string().url().meta({ lg: 12 })),
	content: req(
		string().meta({
			lg: 12,
			minRows: 4,
			multiline: true,
		})
	),
})

export function Form(props) {
	const { initialValues, handleSave } = props

	const creating = initialValues === undefined

	const { t } = useTranslation()
	const navigate = useNavigate()

	const [trigger, { isLoading }] = handlePromise(handleSave, [
		"successful.item",
		{ value: `$t(successful.${creating ? "created" : "updated"})` },
	])

	const fields = Object.entries(formSchema.fields).map(([name, schema]) => {
		return {
			name,
			title: capitalize(name.split("_").join(" ")),
			type: schema.type,
			...schema.describe().meta,
		}
	})

	return (
		<LayoutWithBackButton>
			<CommonForm
				noWrapper
				showErrors
				onSubmit={(values) => {
					values.photo_list = values.photos.map(({ full }) => full)

					return trigger(values)
						.unwrap()
						.then(() => {
							navigate(-1)
						})
						.catch((error) => {
							process.env.NODE_ENV === "development" && console.info({ error })
						})
				}}
				validationSchema={formSchema}
				initialValues={initialValues || {}}
				formFields={[{ label: t("Promotions"), fields }]}
				submitBtnDisabled={isLoading}
			/>
		</LayoutWithBackButton>
	)
}
