import { api } from "services/api"
import { API_ACCOUNT } from "services/endpoints"
import { TAG_ADMIN_USER, TAG_ADMIN_USERS } from "services/tags"

export default api("adminApi").injectEndpoints({
	endpoints(build) {
		return {
			list: build.query({
				query(params) {
					return {
						params,
						method: "GET",
						url: API_ACCOUNT,
					}
				},
				providesTags: [TAG_ADMIN_USERS],
			}),

			get: build.query({
				query(id) {
					return {
						method: "GET",
						url: `${API_ACCOUNT}/${id}`,
					}
				},
				providesTags: [TAG_ADMIN_USER],
			}),

			update: build.mutation({
				query({ id, ...data }) {
					return {
						data,
						method: "PUT",
						url: `${API_ACCOUNT}/${id}`,
					}
				},
				invalidatesTags: (ok) => (ok ? [TAG_ADMIN_USER, TAG_ADMIN_USERS] : []),
			}),

			create: build.mutation({
				query(data) {
					return {
						data,
						method: "POST",
						url: API_ACCOUNT,
					}
				},
				invalidatesTags: (ok) => (ok ? [TAG_ADMIN_USERS] : []),
			}),

			delete: build.mutation({
				query(id) {
					return {
						method: "DELETE",
						url: `${API_ACCOUNT}/${id}`,
					}
				},
				invalidatesTags: (ok) => (ok ? [TAG_ADMIN_USERS] : []),
			}),
		}
	},
})
