import { api } from "services/api"
import { API_PAYMENT } from "services/endpoints"
import { TAG_PAYMENT, TAG_PAYMENTS } from "services/tags"

export default api("paymentsApi").injectEndpoints({
	endpoints(b) {
		return {
			list: b.query({
				query(params) {
					return {
						params,
						url: API_PAYMENT,
					}
				},

				providesTags: [TAG_PAYMENTS],
			}),

			get: b.query({
				query(id) {
					return {
						url: `${API_PAYMENT}/${id}`,
					}
				},

				providesTags: [TAG_PAYMENT],
			}),
		}
	},
})
