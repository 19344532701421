import { commonApi } from "services/api"
import { API_WEDGE_SHAFTS, API_WEDGE_STOCK_SHAFTS } from "services/endpoints"
import { TAG_WEDGE_HEAD, TAG_WEDGE_HEADS, TAG_WEDGE_SHAFT, TAG_WEDGE_SHAFTS } from "services/tags"

const commonInvalidationTags = (ok) =>
	ok ? [TAG_WEDGE_HEAD, TAG_WEDGE_HEADS, TAG_WEDGE_SHAFT, TAG_WEDGE_SHAFTS] : []

export const wedgeStockShaftsApi = commonApi.injectEndpoints({
	endpoints(build) {
		return {
			createWedgeStockShaft: build.mutation({
				query(data) {
					return {
						data,
						url: API_WEDGE_SHAFTS,
						method: "POST",
					}
				},

				invalidatesTags: commonInvalidationTags,
			}),

			getWedgeStockShaftList: build.query({
				query(id) {
					return {
						url: API_WEDGE_STOCK_SHAFTS(id),
					}
				},

				transformResponse: ({ data: shafts }) => {
					return shafts.map((shaft) => ({
						...shaft,
						photo: shaft.photos?.path,
					}))
				},

				providesTags: [TAG_WEDGE_HEAD],
			}),

			updateWedgeStockShaft: build.mutation({
				query(data) {
					return {
						data,
						url: `${API_WEDGE_SHAFTS}/${data.id}`,
						method: "PUT",
					}
				},

				invalidatesTags: commonInvalidationTags,
			}),

			deleteWedgeStockShaft: build.mutation({
				query(id) {
					return {
						url: `${API_WEDGE_SHAFTS}/${id}`,
						method: "DELETE",
					}
				},

				invalidatesTags: commonInvalidationTags,
			}),

			toggleWedgeStockShaftActiveStatus: build.mutation({
				query({ id, is_active }) {
					return {
						data: { is_active },
						method: "PUT",
						url: `${API_WEDGE_SHAFTS}/${id}/status`,
					}
				},

				invalidatesTags: commonInvalidationTags,
			}),
		}
	},
})

export const {
	useCreateWedgeStockShaftMutation,
	useGetWedgeStockShaftListQuery,
	useUpdateWedgeStockShaftMutation,
	useDeleteWedgeStockShaftMutation,
	useToggleWedgeStockShaftActiveStatusMutation,
} = wedgeStockShaftsApi
