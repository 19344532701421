import { format, parse } from "date-fns"
import { enUS, ko } from "date-fns/locale"
import { useTranslation } from "react-i18next"

export const dateFormatter = ({ date, convertTo = "yy.MM.dd" }) => {
	return format(new Date(date), convertTo)
}

export const stringToDate = ({ dateString, formatFrom = "yy.MM.dd", formatTo }) => {
	const date = parse(dateString, formatFrom, new Date())
	return format(date, formatTo)
}

export const formatDate = (date, output = "yyyy.MM.dd HH:mm", ...rest) => {
	try {
		return format(new Date(date), output, ...rest)
	} catch (err) {
		return date
	}
}

export function defaultDateFormat(date, output = "yyyy.MM.dd") {
	try {
		return format(new Date(date), output)
	} catch (err) {
		return date
	}
}

export const dobFormatter = defaultDateFormat

export function parse24hrs(date) {
	return parse(date, "kkmm", new Date())
}

export function format24hrs(date, locale) {
	return formatDate(parse24hrs(date), "aa HH:mm", {
		locale,
	})
}

export function startEnd(startTime, endTime) {
	const { i18n } = useTranslation()

	const locale = i18n.language === "kr" ? ko : enUS

	const start = format24hrs(startTime, locale)

	const end = format24hrs(endTime, locale)

	return `${start} ~ ${end}`
}
