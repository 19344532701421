import { api } from "services/api"
import { API_MOBILE_SETTINGS, API_SITE_SETTINGS } from "services/endpoints"
import { TAG_SETTINGS_APPVERSION } from "services/tags"

export default api("settingsApi").injectEndpoints({
	endpoints(b) {
		return {
			version: b.query({
				query() {
					return {
						url: `${API_SITE_SETTINGS}/index`,
					}
				},

				providesTags: [TAG_SETTINGS_APPVERSION],
			}),

			setVersion: b.mutation({
				query(data) {
					return {
						data,
						method: "POST",
						url: `${API_SITE_SETTINGS}/update`,
					}
				},

				invalidatesTags: (ok) => (ok ? [TAG_SETTINGS_APPVERSION] : []),
			}),

			uploadApp: b.mutation({
				queryFn({ formData, ...axiosOpts }, queryApi, extraOptions, baseQuery) {
					return baseQuery({
						data: formData,
						method: "POST",
						url: `${API_SITE_SETTINGS}/app`,
						headers: { "Content-Type": "multipart/form-data" },
						...axiosOpts,
					})
				},
			}),

			mobileInfo: b.query({
				query() {
					return {
						url: API_MOBILE_SETTINGS,
					}
				},
			}),

			updateMobileInfo: b.mutation({
				query(data) {
					return {
						data,
						method: "POST",
						url: API_MOBILE_SETTINGS,
					}
				},
			}),
		}
	},
})
