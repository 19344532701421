import { useSelector } from "react-redux"

export const USER_STORE = "store"
export const USER_REMOVE = "remove"

const initialState = {
	id: null,
	name: "",
	email: "",
	role: null,
}

const reducer = (state = initialState, { type, id, email, name, role }) => {
	switch (type) {
		case USER_STORE:
			return { ...state, id, email, name, role }
		case USER_REMOVE:
			return { ...initialState }
		default:
			return state
	}
}

export const storeUser = (id, name, email, role) => (dispatch) => {
	dispatch({ type: USER_STORE, id, name, email, role })
}

export const removeUser = () => (dispatch) => {
	dispatch({ type: USER_REMOVE })
}

export default reducer

export const useUser = () => {
	const user = useSelector((state) => state.user)

	return user
}
