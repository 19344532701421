import { api } from "services/api"
import { API_BODY_TYPES } from "services/endpoints"
import { TAG_BODY_TYPE, TAG_BODY_TYPES } from "services/tags"

export const bodyTypesApi = api("bodyTypesApi").injectEndpoints({
	endpoints(b) {
		return {
			list: b.query({
				query(params) {
					return {
						params,
						url: API_BODY_TYPES,
					}
				},

				providesTags: [TAG_BODY_TYPES],
			}),

			get: b.query({
				query(id) {
					return {
						url: `${API_BODY_TYPES}/${id}`,
					}
				},

				transformResponse: ({ data }) => data,

				providesTags: [TAG_BODY_TYPE],
			}),
		}
	},
})
