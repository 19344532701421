import { commonApi } from "services/api"
import { API_CLUB_STOCK_SHAFTS, API_DRIVER_SHAFTS } from "services/endpoints"
import {
	TAG_DRIVER_HEAD,
	TAG_DRIVER_HEADS,
	TAG_DRIVER_SHAFT,
	TAG_DRIVER_SHAFTS,
} from "services/tags"

const commonInvalidationTags = (ok) =>
	ok ? [TAG_DRIVER_HEAD, TAG_DRIVER_HEADS, TAG_DRIVER_SHAFTS, TAG_DRIVER_SHAFT] : []

export const driverStockShaftsApi = commonApi.injectEndpoints({
	endpoints(build) {
		return {
			createDriverStockShaft: build.mutation({
				query(data) {
					return {
						data,
						url: API_DRIVER_SHAFTS,
						method: "POST",
					}
				},

				invalidatesTags: commonInvalidationTags,
			}),

			getDriverStockShaftList: build.query({
				query(id) {
					return {
						url: API_CLUB_STOCK_SHAFTS(id),
					}
				},

				transformResponse: ({ data: shafts }) => {
					return shafts.map((shaft) => ({
						...shaft,
						photo: shaft.photos?.path,
					}))
				},

				providesTags: [TAG_DRIVER_HEAD],
			}),

			updateDriverStockShaft: build.mutation({
				query(data) {
					return {
						data,
						url: `${API_DRIVER_SHAFTS}/${data.id}`,
						method: "PUT",
					}
				},

				invalidatesTags: commonInvalidationTags,
			}),

			deleteDriverStockShaft: build.mutation({
				query(id) {
					return {
						url: `${API_DRIVER_SHAFTS}/${id}`,
						method: "DELETE",
					}
				},

				invalidatesTags: commonInvalidationTags,
			}),

			toggleDriverStockShaftActiveStatus: build.mutation({
				query({ id, is_active }) {
					return {
						method: "POST",
						data: { is_active },
						url: `${API_DRIVER_SHAFTS}/${id}/status`,
					}
				},

				invalidatesTags: commonInvalidationTags,
			}),
		}
	},
})

export const {
	useCreateDriverStockShaftMutation,
	useGetDriverStockShaftListQuery,
	useUpdateDriverStockShaftMutation,
	useDeleteDriverStockShaftMutation,
	useToggleDriverStockShaftActiveStatusMutation,
} = driverStockShaftsApi
