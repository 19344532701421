import { api } from "services/api"
import { API_BALL_INFO, API_BALL_INFO_SYNC_STORES } from "services/endpoints"
import { TAG_BALL, TAG_BALLS } from "services/tags"

function syncStores(baseQuery, data) {
	return baseQuery({
		data,
		method: "POST",
		url: API_BALL_INFO_SYNC_STORES,
	})
}

const invalidatesTags = (success) => (success ? [TAG_BALLS] : [])

export default api("ballsApi").injectEndpoints({
	endpoints(b) {
		return {
			all: b.query({
				query(params) {
					return {
						params,
						url: API_BALL_INFO,
					}
				},

				providesTags: [TAG_BALLS],
			}),

			toggleField: b.mutation({
				query({ id, field, ...data }) {
					return {
						data,
						method: "POST",
						url: `${API_BALL_INFO}/${id}/${field}`,
					}
				},

				invalidatesTags: (ok) => (ok ? [TAG_BALL, TAG_BALLS] : []),
			}),

			delete: b.mutation({
				query(id) {
					return {
						method: "DELETE",
						url: `${API_BALL_INFO}/${id}`,
					}
				},

				invalidatesTags,
			}),

			get: b.query({
				query(id) {
					return {
						url: `${API_BALL_INFO}/${id}`,
					}
				},

				transformResponse({ data: { brand, ...data } }) {
					return {
						...data,
						brand_id: brand?.id,
						photo: data.photos?.path,
						colors: data.colors?.map(({ color }) => color),
						store_ids: data.stores?.map((store) => store.id),
					}
				},

				providesTags: [TAG_BALL],
			}),

			create: b.mutation({
				async queryFn({ store_ids, ...data }, queryApi, extraOptions, baseQuery) {
					const { data: response, error } = await baseQuery({
						data,
						method: "POST",
						url: API_BALL_INFO,
					})

					if (error) return { error }

					return await syncStores(baseQuery, {
						ball_id: response.data.id,
						store_id_list: store_ids,
					})
				},

				invalidatesTags,
			}),

			update: b.mutation({
				async queryFn({ id, store_ids, ...data }, queryApi, extraOptions, baseQuery) {
					const { error } = await syncStores(baseQuery, {
						ball_id: id,
						store_id_list: store_ids,
					})

					if (error) return { error }

					return await baseQuery({
						data,
						method: "PATCH",
						url: `${API_BALL_INFO}/${id}`,
					})
				},

				invalidatesTags: (ok) => (ok ? [TAG_BALLS, TAG_BALL] : []),
			}),

			list: b.query({
				query() {
					return {
						url: `${API_BALL_INFO}/list`,
					}
				},

				transformResponse: ({ data }) => data,
			}),
		}
	},
})
