/* eslint-disable no-case-declarations */
import { yupResolver } from "@hookform/resolvers/yup"
import Autocomplete from "@mui/material/Autocomplete"
import Grid from "@mui/material/Grid"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import Stack from "@mui/material/Stack"
import Switch from "@mui/material/Switch"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { PostCode } from "common/PostCode"
import FormCard from "components/FormCard"
import FormLabel from "components/FormLabel"
import ButtonWithIcon from "components/FormSaveButton"
import ImageUpload from "components/ImageUpload"
import MDBox from "components/MDBox"
import MDButton from "components/MDButton"
import { isFunction } from "lodash"
import { Fragment, forwardRef, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { IMaskInput } from "react-imask"
import { useBrandsAsField } from "services/modules/brands"
import { getErrorMessage } from "utils/helpers"

export const ErrorMessage = ({ message, ...props }) => {
	const { t } = useTranslation()

	return message ? (
		<Typography fontSize={12} mt={1} color="error" {...props}>
			{t(message)}
		</Typography>
	) : null
}

export default function Form({
	formFields,
	initialValues = {},
	validationSchema,
	onSubmit,
	serverErrors,
	submitBtnDisabled,
	noWrapper,
	showErrors,
	customSubmitLabel,
}) {
	const { t } = useTranslation()

	const methods = useForm({
		defaultValues: initialValues,
		resolver: validationSchema && yupResolver(validationSchema),
	})

	formFields = isFunction(formFields) ? formFields(methods) : formFields

	const {
		register,
		setError,
		handleSubmit,
		control,
		getValues,
		formState: { errors },
	} = methods

	useEffect(() => {
		if (serverErrors) {
			Object.keys(serverErrors).forEach((key) =>
				setError(key, {
					type: serverErrors[key].type,
					message: serverErrors[key].message,
				})
			)
		}
	}, [serverErrors])

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	const Wrapper = noWrapper ? Fragment : FormCard

	return (
		<form
			noValidate
			onSubmit={handleSubmit(
				(data) => {
					return showErrors
						? new Promise((resolve) => {
								onSubmit(data)
									.then(resolve)
									.catch((requestError) => {
										const errors = getErrorMessage(requestError)

										if (typeof errors !== "string") {
											Object.keys(errors).forEach((key) =>
												setError(key, {
													type: errors[key].type,
													message: errors[key].message,
												})
											)
										}
									})
						  })
						: onSubmit(data)
				},
				process.env.NODE_ENV === "development" && console.error
			)}
		>
			{formFields?.map(({ label, fields }, idx) => (
				<Wrapper key={`form-${label}-${idx}`}>
					{label &&
						(typeof label === "string" ? (
							<Typography paddingBottom={2}>{t(label)}</Typography>
						) : (
							label
						))}

					<Grid container spacing={3}>
						{fields.map((field, id) => {
							const {
								name,
								title,
								maxLength,
								minLength,
								type,
								selectValues = [],
								render,
								disabled,
								...rest
							} = field

							return (
								<Field
									key={`${idx}-${name}-${id}`}
									name={name}
									title={t(title)}
									type={type}
									register={register}
									control={control}
									error={errors[name]}
									selectValues={selectValues}
									initialValue={getValues(name)}
									maxLength={maxLength}
									minLength={minLength}
									render={render}
									disabled={disabled ?? false}
									methods={methods}
									{...rest}
								/>
							)
						})}
					</Grid>
				</Wrapper>
			))}

			<FormSaveButton disabled={submitBtnDisabled} customSubmitLabel={customSubmitLabel} />
		</form>
	)
}

export function FormSaveButton({ customSubmitLabel, ...props }) {
	const { t } = useTranslation()

	return <ButtonWithIcon {...props}>{customSubmitLabel ?? t("common.save")}</ButtonWithIcon>
}

export function ItemWrapper({ children, xs = 12, lg = 6, title, error = {} }) {
	return (
		<Grid item xs={xs} lg={lg}>
			{title && <FormLabel>{title}</FormLabel>}

			{children}

			<ErrorMessage message={error?.message} />
		</Grid>
	)
}

export function Field({
	name,
	title,
	maxLength,
	minLength,
	type,
	isFreeSolo,
	selectValues,
	error,
	initialValue,
	disabled = false,
	render,
	methods,
	register,
	...props
}) {
	const [postCodeOpen, setPostCodeOpen] = useState(false)
	const { t } = useTranslation()

	const { watch, setValue, getValues, trigger, control } = methods

	const watchedValue = watch(name)

	switch (type) {
		case "select":
			return (
				<SelectInner
					title={title}
					register={register}
					name={name}
					error={error}
					initialValue={initialValue}
					disabled={disabled}
					selectValues={selectValues}
					methods={methods}
					{...props}
				/>
			)

		case "selectLimitTag":
			const selectedValue = selectValues.filter((value) =>
				(initialValue || []).some((selected) => value.value === selected)
			)

			return (
				<ItemWrapper xs={props.xs} lg={props.lg} error={error} title={title}>
					<Controller
						name={name}
						control={control}
						render={({ field }) => (
							<Autocomplete
								disableCloseOnSelect
								key={name + selectedValue.length}
								id={name}
								multiple
								limitTags={15}
								freeSolo={isFreeSolo}
								options={selectValues}
								getOptionLabel={(option) =>
									isFreeSolo && typeof option === "string" ? option : option.name
								}
								// only retrieve value (id) of object
								onChange={(_, items) => {
									const values = items.map((item) =>
										isFreeSolo && typeof item === "string" ? item : item.value
									)
									field.onChange(values)
								}}
								isOptionEqualToValue={(option, value) => {
									const isFreeSoloAndOptionString = isFreeSolo && typeof option === "string"
									const isFreeSoloAndValueString = isFreeSolo && typeof value === "string"

									const optionVal = isFreeSoloAndOptionString ? option : option.value
									const valueVal = isFreeSoloAndValueString ? value : value.value
									return optionVal === valueVal
								}}
								defaultValue={selectedValue || []}
								renderInput={(params) => <TextField {...params} error={Boolean(error?.message)} />}
								renderOption={(props, option) => (
									<li {...props} key={option.id}>
										{option.name}
									</li>
								)}
							/>
						)}
					/>
				</ItemWrapper>
			)
		case "selectMultiple":
			return (
				<ItemWrapper xs={props.xs} lg={props.lg} error={error} title={title}>
					<Controller
						name={name}
						control={control}
						render={({ field }) => (
							<Autocomplete
								disableCloseOnSelect
								key={name + initialValue?.length}
								id={name}
								multiple
								limitTags={15}
								freeSolo={isFreeSolo}
								options={selectValues}
								onChange={(_, items) => {
									field.onChange(items)
								}}
								defaultValue={initialValue || []}
								renderInput={(params) => <TextField {...params} error={Boolean(error?.message)} />}
							/>
						)}
					/>
				</ItemWrapper>
			)
		case "selectFreeSolo":
			return (
				<ItemWrapper xs={props.xs} lg={props.lg} error={error} title={title}>
					<Autocomplete
						defaultValue={initialValue}
						onInputChange={(_, item) => {
							setValue(name, item)
							trigger(name)
						}}
						freeSolo
						key={name}
						id={name}
						options={selectValues}
						renderInput={(params) => <TextField error={Boolean(error?.message)} {...params} />}
					/>
				</ItemWrapper>
			)

		case "linebreak":
			return <Grid item xs={props.xs || 12} lg={props.lg || 12} />

		case "custom":
			return render({ methods, t })

		case "switch":
			const { leftText, rightText, inline } = props

			return (
				<ItemWrapper xs={props.xs} lg={props.lg} error={error}>
					{!inline && <FormLabel>{title}</FormLabel>}

					<Stack direction="row" alignItems="center">
						{inline && <FormLabel sx={{ mt: 0.5 }}>{title}</FormLabel>}

						<Typography fontSize={12}>{leftText}</Typography>

						<Switch
							disabled={disabled}
							onChange={(event) => {
								setValue(name, event.target.checked)
							}}
							checked={getValues(name) ?? false}
						/>
						<Typography fontSize={12}>{rightText}</Typography>
					</Stack>
				</ItemWrapper>
			)

		case "image_upload":
			return (
				<ItemWrapper xs={props.xs} lg={props.lg} error={error} title={title}>
					<ImageUpload
						error={Boolean(error?.message)}
						imageSrc={getValues(name)}
						handleSelectImage={(value) => {
							setValue(name, value)
							trigger(name)
						}}
					/>
				</ItemWrapper>
			)

		case "brand_id":
			const [brands] = useBrandsAsField(props.filter)

			return (
				<SelectInner
					title={t("routes.brands")}
					register={register}
					name={name}
					error={error}
					initialValue={initialValue}
					disabled={disabled}
					selectValues={brands}
					{...props}
				/>
			)

		case "address":
			return (
				<ItemWrapper xs={props.xs} lg={props.lg} error={error} title={title}>
					<PostCode
						isOpen={postCodeOpen}
						setIsOpen={setPostCodeOpen}
						onComplete={({ address, zonecode }) => {
							setValue(name, address)
							setValue("postal_code", zonecode)
						}}
					/>
					<MDBox display="flex" gap={1}>
						<TextField
							key={name}
							{...register(name)}
							type={type}
							name={name}
							onWheel={(e) => e.target.blur()}
							fullWidth
							title={title}
							error={Boolean(error?.message)}
							inputProps={{
								maxLength,
								minLength,
								readOnly: true,
							}}
							onClick={() => setPostCodeOpen((prev) => !prev)}
							{...props}
						/>
						<MDButton
							variant="gradient"
							color="info"
							onClick={() => setPostCodeOpen((prev) => !prev)}
							sx={{ whiteSpace: "nowrap" }}
						>
							{t("common.search")}
						</MDButton>
					</MDBox>
				</ItemWrapper>
			)

		default:
			const InputProps = {}

			if (type === "tel") {
				InputProps.inputComponent = TextMaskCustom
				props.value = watchedValue
			}

			return (
				<ItemWrapper xs={props.xs} lg={props.lg} error={error} title={title}>
					<TextField
						key={name}
						{...register(name)}
						type={type}
						name={name}
						onWheel={(e) => e.target.blur()}
						fullWidth
						title={title}
						error={Boolean(error?.message)}
						inputProps={{
							maxLength,
							minLength,
						}}
						InputProps={InputProps}
						disabled={disabled}
						{...props}
					/>
				</ItemWrapper>
			)
	}
}

export const TextMaskCustom = forwardRef(function TextMaskCustom(
	{ onChange, onBlur, ...props },
	ref
) {
	return (
		<IMaskInput
			{...props}
			overwrite
			inputRef={ref}
			mask="000-0000-0000"
			onAccept={(value) => onChange({ target: { name: props.name, value } })}
			onBlur={({ target }) =>
				onBlur({ target: { name: props.name, value: target.value.replaceAll(/-/g, "") } })
			}
		/>
	)
})

export function SelectInner({
	title,
	register,
	name,
	error,
	initialValue,
	disabled,
	selectValues,
	onChangeEffect = new Function(),
	methods,
	...props
}) {
	const { onChange: onFieldChange, ...fieldProps } = register(name)

	const onChange = (e) => {
		onFieldChange(e)
		onChangeEffect(e, methods)
	}

	return (
		<ItemWrapper xs={props.xs} lg={props.lg} error={error} title={title}>
			<Select
				{...fieldProps}
				onChange={onChange}
				fullWidth
				error={Boolean(error?.message)}
				defaultValue={initialValue}
				sx={{ height: "45px" }}
				MenuProps={{ PaperProps: { sx: { maxHeight: 220 } } }}
				disabled={disabled}
			>
				{selectValues.map(({ name, value }) => (
					<MenuItem key={name + value} value={value}>
						{name}
					</MenuItem>
				))}
			</Select>
		</ItemWrapper>
	)
}
