import { format, parse } from "date-fns/esm"
import { api } from "services/api"
import { API_STORES } from "services/endpoints"

const url = (id) => `${API_STORES}/${id}/operating-hours`

export default api("storeOperatingHours").injectEndpoints({
	endpoints(b) {
		return {
			get: b.query({
				query(id) {
					return {
						url: url(id),
					}
				},

				transformResponse: ({ data }) =>
					data.map(({ id, ...item }) => {
						item.oh_id = id
						item.long = format(parse(item.code, "ccc", new Date()), "cccc")

						return item
					}),
			}),

			save: b.mutation({
				query({ id, items }) {
					return {
						url: url(id),
						method: "PUT",
						data: { items: items.map(({ oh_id, ...item }) => ({ ...item, id: oh_id })) },
					}
				},
			}),
		}
	},
})
