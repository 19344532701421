import { Card } from "@mui/material"
import React from "react"

const styles = {
	marginTop: "20px",
	padding: "20px",
}

// eslint-disable-next-line
function FormCard({ children, ...props }) {
	return (
		<Card sx={styles} {...props}>
			{children}
		</Card>
	)
}

export default FormCard
