export const container = {
	width: "150px",
	height: "150px",
	backgroundColor: "#fafafa",
	backgroundRepeat: "no-repeat",
	backgroundSize: "cover",
	backgroundPosition: "center",
	border: "1px solid rgba(0, 0, 0, 0.2)",
	borderRadius: "100%",
	cursor: "pointer",
	position: "relative",
}

export const shaftContainer = {
	width: "50%",
	height: "150px",
	backgroundColor: "#fafafa",
	// backgroundRepeat: "no-repeat",
	backgroundSize: "contain",
	backgroundPosition: "center",
	border: "2px solid rgba(0, 0, 0, 0.2)",
	borderRadius: 15,
	cursor: "pointer",
	marginBottom: 16,
	overflow: "hidden",
	position: "relative",
}

export const input = {
	width: "100%",
	height: "100%",
	opacity: "0",
	borderRadius: "100%",
	cursor: "pointer",
	display: "block",
}

export const svg = {
	width: "40%",
	fill: "none",
	stroke: "rgba(0, 0, 0, 0.5)",
	margin: "auto",
	position: "absolute",
	left: "50%",
	top: "50%",
	transform: "translate(-50%, -50%)",
}

export const svgTwo = {
	width: "10%",
	fill: "none",
	stroke: "rgba(0,0,0,0.5)",
	margin: "auto",
	position: "absolute",
	left: "50%",
	top: "50%",
	transform: "translate(-50%, -50%)",
}

export const styles = { container, shaftContainer, input, svg, svgTwo }

export default styles
